import { t } from 'i18next';
import iziToast from 'izitoast';
import { throttle } from 'lodash-es';
import moment from 'moment';

import type { AnyAction, Middleware, MiddlewareAPI, Store } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import type { Socket } from 'socket.io-client';

import { FETCH_PERSONAL_DATA_SUCCESS } from 'src/actions';
import { logout, refreshToken } from 'src/actions/authActions';
import { fetchCategories } from 'src/actions/categoryActions';
import { fetchChats } from 'src/actions/chatActions';
import { fetchEIdentifications } from 'src/actions/eIdentificationActions';
import { deleteEntityTagsSuccess, updateEntityTagsSuccess } from 'src/actions/entityTagsActions';
import { fetchFilterPresets } from 'src/actions/filterPresetsActions';
import { fetchInfoPages } from 'src/actions/infoPagesActions';
import { removeInfopageFromInfopagelist, updateInfoPageInList } from 'src/actions/infoPagesActionsRTK';
import { showDisconnectedNotification } from 'src/actions/notificationsActions';
import { fetchPriorities } from 'src/actions/priorityActions';
import { deleteAutoSuggestionSuccess, fetchAutoSuggestion } from 'src/actions/suggestionActions';
import { addHighlightToTab, closeTab } from 'src/actions/tabActionsRTK';
import { fetchTags } from 'src/actions/tagActions';
import {
  closeTicketTabAndNavigate,
  fetchChannelTypes,
  fetchResponseTemplates,
  fetchTicket,
  fetchTickets,
  fetchTicketTypes
} from 'src/actions/ticketsActions';
import {
  removeTicketFromDetailed,
  removeTicketFromTicketlist,
  updateTicketInTicketlist
} from 'src/actions/ticketsActionsRTK';
import { fetchPersonalData, fetchUsers } from 'src/actions/userActions';
import { fetchWebhooks } from 'src/actions/webhooksActions';
import { updateWorkStatus } from 'src/actions/workStatusActions';
import { FilterPresetView } from 'src/Components/Management/FilterPresets/types';
import SocketInstance from 'src/realTimeNotifications';
import { updateChatVisitorTypeStatusSuccess } from 'src/reducers/chatTypeStatusReducer';
import { transcriptionsUpdated } from 'src/reducers/transcriptionsReducer';
import { StaticTabs } from 'src/types/TicketList';
import { Roles } from 'src/types/User';
import NotificationHandler from 'src/Utilities/NotificationHandler';
import { userToNumericalId } from 'src/Utilities/users';

import type { UpdateWorkStatusData } from 'src/actions/workStatusActions';
import type { TranscriptionMessage } from 'src/Components/Case/Widget/transcriptionsWidgetTypes';
import type { ChatStatusData } from 'src/types/ChatTypeStatus';
import type { ContentTypes } from 'src/types/ContentTypes';
import type { State } from 'src/types/initialState';
import type { TicketListTicket } from 'src/types/Ticket';

interface TicketRealtime extends Omit<TicketListTicket, 'delegates'> {
  type: ContentTypes;
  deprecated: number;
}
interface ContentUpdatedData {
  ticketId: string;
  ticket: TicketRealtime;
  UID: number;
  lastAckedMessage: number;
  messageIdentifier: string;
}
type API = MiddlewareAPI<ThunkDispatch<State, any, AnyAction>, State>;

const throttledUsersUpdate = throttle((dispatch) => {
  return dispatch(fetchUsers());
}, 1000);

const handleDeprecated = (ticket: TicketRealtime, api: API) => {
  const isTabOpen = api
    .getState()
    .ticketTabs.map((t) => t.id)
    .includes(ticket.id);

  if (isTabOpen) {
    api.dispatch(closeTab(ticket.id));
    api.dispatch(removeTicketFromDetailed(ticket.id));
  }

  api.dispatch(removeTicketFromTicketlist({ ticketId: ticket.id, id: StaticTabs.MAIN_VIEW }));
};

const handleContentByContentType = (data: ContentUpdatedData, api: API) => {
  const ticket = data.ticket;

  switch (ticket.type) {
    case 'infopage':
      api.dispatch(updateInfoPageInList(ticket, StaticTabs.MAIN_VIEW));
      break;
    case 'task':
      api.dispatch(updateTicketInTicketlist({ ticket, id: StaticTabs.MAIN_VIEW }));
      break;
    default:
      console.error('This type does not exist', data);
      break;
  }
};

export const socketInitMiddleware: Middleware = (api: API) => (next) => (action) => {
  const returnValue = next(action);
  const NotificationHandlerInstance = new NotificationHandler(api as Store);

  if (FETCH_PERSONAL_DATA_SUCCESS === action.type) {
    SocketInstance.initialize(action.payload.UID, {
      disconnect: () => (reason: Socket.DisconnectReason) => {
        if (reason === 'io server disconnect' || reason === 'io client disconnect') {
          if (localStorage.getItem('loggedIn') && SocketInstance.isInitialized) {
            api.dispatch(logout());
          }
        } else {
          // Disconnected due to network issues or service inaccessibility
          api.dispatch(refreshToken());
        }

        SocketInstance.socket.io.opts.query = {
          UID: SocketInstance._userId,
          ...(!!localStorage.getItem('lastAckedMessage') && {
            lastAckedMessage: localStorage.getItem('lastAckedMessage')
          })
        };
        api.dispatch(showDisconnectedNotification(true));
      },

      connect: () => () => {
        localStorage.setItem('lastAckedMessage', String(moment().valueOf()));
        api.dispatch(showDisconnectedNotification(false));
      },

      connect_error: () => () => {
        api.dispatch(refreshToken());
        api.dispatch(showDisconnectedNotification(true));
      },

      contentUpdated: (acknowledgeMessage) => (data: ContentUpdatedData) => {
        const { ticketId: updatedTicketId, ticket, UID, lastAckedMessage, messageIdentifier } = data;
        const type = ticket?.type;
        const reduxState = api.getState();
        const loggedInUserUID = reduxState.userData.UID;
        localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);

        const detailedTicketToRefresh = reduxState.detailedTickets.find((ticket) => ticket.id === updatedTicketId);
        const loggedInUserData = reduxState.usersList.usersList.find((user) => user.UID === loggedInUserUID);
        const hasPermissionToShowOnList =
          loggedInUserData &&
          (!Roles.isAnyOf(loggedInUserData.role.id, Roles.Delegated, Roles.DelegatedStrict) ||
            ticket.delegatedTo?.includes(loggedInUserUID));

        if (detailedTicketToRefresh && !ticket.deprecated) {
          api.dispatch(
            fetchTicket({
              id: updatedTicketId,
              closeTicketAfterFail: false,
              shouldActivateTicket: false,
              forceFetch: true,
              type
            })
          );
        }

        if (
          userToNumericalId(loggedInUserUID) !== UID &&
          reduxState.ticketTabs.find((tab) => tab.id === updatedTicketId)
        ) {
          api.dispatch(addHighlightToTab(updatedTicketId));
        }

        if (ticket?.deprecated === 1) {
          handleDeprecated(ticket, api);
        } else if (hasPermissionToShowOnList) {
          handleContentByContentType(data, api);
        }

        NotificationHandlerInstance.handleTicket(ticket);
        acknowledgeMessage?.(messageIdentifier);
      },

      personalDataUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          api.dispatch(fetchPersonalData());
          acknowledgeMessage?.(messageIdentifier);
        },

      transcriptionsUpdated: () => (payload: { contentId: string; message: TranscriptionMessage }) => {
        api.dispatch(transcriptionsUpdated({ contentId: payload.contentId, message: payload.message }));
      },

      responseTemplatesUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          api.dispatch(fetchResponseTemplates());
          acknowledgeMessage?.(messageIdentifier);
        },

      updateTicketsChatVisitorTypeStatus:
        (acknowledgeMessage) =>
        (payload: { message: string; data: ChatStatusData; lastAckedMessage: number; messageIdentifier: string }) => {
          try {
            const { data, lastAckedMessage, messageIdentifier } = payload;

            localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
            api.dispatch(updateChatVisitorTypeStatusSuccess(data));
            acknowledgeMessage?.(messageIdentifier);
          } catch (error) {
            console.error('updateTicketsChatVisitorTypeStatus: ', error);
          }
        },

      tagsUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          api.dispatch(fetchTags());
          acknowledgeMessage?.(messageIdentifier);
        },

      channelsUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          api.dispatch(fetchChannelTypes());
          acknowledgeMessage?.(messageIdentifier);
        },

      categoriesUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          api.dispatch(fetchCategories());
          acknowledgeMessage?.(messageIdentifier);
        },

      caseTicketTypeChanged:
        (acknowledgeMessage) =>
        async (payload: {
          data: { caseId: number; type: string; ticketType: number };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { data, lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          const isUserHasAccessToTicketType = api
            .getState()
            .ticketTypes.find((ticketType) => ticketType.id === data.ticketType);

          if (!isUserHasAccessToTicketType) {
            switch (data.type) {
              case 'task': {
                const ticketId = `TSK${payload.data.caseId}`;
                const ticketIsOpen = api.getState().ticketTabs.filter((tab) => tab.id === ticketId).length > 0;

                if (ticketIsOpen) {
                  api.dispatch(closeTicketTabAndNavigate({ contentId: ticketId }));

                  iziToast.error({
                    title: `${t('ERROR')}!`,
                    icon: 'icon delete',
                    message: `Ticket ${ticketId} was closed due to the lack of permissions`,
                    timeout: 5000
                  });
                }
                api.dispatch(
                  removeTicketFromTicketlist({
                    ticketId,
                    id: StaticTabs.MAIN_VIEW
                  })
                );
                break;
              }

              case 'infopage': {
                const infopageId = `INF${payload.data.caseId}`;
                const infopageIsOpen =
                  api.getState().ticketTabs.filter((tab) => tab.id === infopageId && tab.activeTab).length > 0;

                if (infopageIsOpen) {
                  api.dispatch(closeTab(infopageId));
                  api.dispatch(removeTicketFromDetailed(infopageId));
                  iziToast.error({
                    title: `${t('ERROR')}!`,
                    icon: 'icon delete',
                    message: `Infopage ${infopageIsOpen} was closed due to the lack of permissions`,
                    timeout: 5000
                  });
                }
                api.dispatch(removeInfopageFromInfopagelist(infopageId, StaticTabs.MAIN_VIEW));
                break;
              }

              default: {
                break;
              }
            }
          }
          acknowledgeMessage?.(messageIdentifier);
        },

      statusUpdates: () => (data: UpdateWorkStatusData) => {
        api.dispatch(updateWorkStatus(data));
      },

      usersUpdated:
        (acknowledgeMessage) =>
        async (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          acknowledgeMessage?.(messageIdentifier);

          await throttledUsersUpdate(api.dispatch);
          const { usersList, userData } = api.getState();
          const user = usersList.usersList.find(({ UID }) => UID === userData.UID);
          if (user?.role.id === 'ROL3') {
            api.dispatch(logout());
          }
        },

      ticketTypesUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          api.dispatch(fetchTicketTypes());
          acknowledgeMessage?.(messageIdentifier);
        },

      userTicketTypeChanged:
        (acknowledgeMessage) =>
        async (payload: {
          data: { type: 'add' | 'remove'; name: string };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { data, lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          SocketInstance.emit('updateRooms', { roomName: data.name, type: data.type });

          await api.dispatch(refreshToken(false));
          await api.dispatch(fetchTicketTypes());
          api.dispatch(fetchTickets(null, StaticTabs.MAIN_VIEW));
          api.dispatch(fetchInfoPages(undefined, StaticTabs.MAIN_VIEW, false));

          acknowledgeMessage?.(messageIdentifier);
        },

      autoSuggestionsUpdated:
        (acknowledgeMessage) =>
        (payload: {
          data: { type: 'add' | 'update' | 'remove'; autoSuggestionId: number };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { lastAckedMessage, messageIdentifier, data } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          acknowledgeMessage?.(messageIdentifier);

          switch (data.type) {
            case 'add':
            case 'update':
              api.dispatch(fetchAutoSuggestion(data.autoSuggestionId));
              break;
            case 'remove': {
              api.dispatch(deleteAutoSuggestionSuccess({ id: data.autoSuggestionId }));
              break;
            }
          }
        },

      chatStatusesUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          api.dispatch(fetchChats());
          acknowledgeMessage?.(messageIdentifier);
        },

      prioritiesUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          api.dispatch(fetchPriorities());
          acknowledgeMessage?.(messageIdentifier);
        },

      eIdentificationChanged:
        (acknowledgeMessage) =>
        (payload: { data: { ticketId: number }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { data, lastAckedMessage, messageIdentifier } = payload;
          const { ticketId } = data;
          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          const reduxState = api.getState();
          const detailedTicketToRefresh = reduxState.detailedTickets.find((ticket) => ticket.id === `TSK${ticketId}`);

          if (detailedTicketToRefresh) {
            api.dispatch(fetchEIdentifications({ contentId: data.ticketId }));
          }

          acknowledgeMessage?.(messageIdentifier);
        },

      filterPresetsChanged:
        (acknowledgeMessage) =>
        (payload: { data: { message: number }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;
          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          const reduxState = api.getState();
          if (!reduxState.filterPresets.loaded) {
            return;
          }

          api.dispatch(
            fetchFilterPresets({
              view: reduxState.router.location.pathname.startsWith('/infopage')
                ? FilterPresetView.infopage
                : FilterPresetView.main
            })
          );

          acknowledgeMessage?.(messageIdentifier);
        },

      entityTagsUpdated:
        (acknowledgeMessage) =>
        (payload: {
          data: { entityId: string; tagIds: number[]; eventType: 'attached' | 'detached' };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { data, lastAckedMessage, messageIdentifier } = payload;
          const { entityId, tagIds, eventType } = data;
          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);

          const reduxState = api.getState();
          const detailedTicketRelationToEntity = reduxState.detailedTickets.find(
            (ticket) => !!ticket.entities.find((entity) => entity._id === entityId)
          );

          if (detailedTicketRelationToEntity) {
            switch (eventType) {
              case 'attached': {
                api.dispatch(updateEntityTagsSuccess({ entityId, tagIds }));
                break;
              }
              case 'detached': {
                api.dispatch(deleteEntityTagsSuccess({ entityId, tagIds }));
                break;
              }
              default: {
                console.error('Unknown event type under entityTagsUpdated handler');
                break;
              }
            }
          }

          acknowledgeMessage?.(messageIdentifier);
        },

      entityAttached:
        (acknowledgeMessage) =>
        (payload: {
          data: { contentId: number; entityId: string };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { lastAckedMessage, messageIdentifier } = payload;
          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          acknowledgeMessage?.(messageIdentifier);
        },

      entityDetached:
        (acknowledgeMessage) =>
        (payload: {
          data: { contentId: number; entityId: string };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { lastAckedMessage, messageIdentifier } = payload;
          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          acknowledgeMessage?.(messageIdentifier);
        },

      webhooksUpdated:
        (acknowledgeMessage) =>
        async (payload: { data: { message: string }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          acknowledgeMessage?.(messageIdentifier);

          api.dispatch(fetchWebhooks());
        }
    });
  }

  return returnValue;
};
