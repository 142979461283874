import React, { useCallback } from 'react';
import { Button, Popup } from 'semantic-ui-react';

import type { FC } from 'react';

import { useFocusMode } from './FocusModeContext';

const FocumModeButton: FC = () => {
  const { isFocusMode, toggleFocusMode } = useFocusMode();

  const renderTrigger = useCallback(() => {
    return (
      <Button
        onClick={toggleFocusMode}
        circular
        basic={!isFocusMode}
        color={isFocusMode ? 'black' : undefined}
        size="tiny"
        icon="eye"
      />
    );
  }, [isFocusMode]);

  return <Popup content="Focus Mode" trigger={renderTrigger()} inverted />;
};

export default FocumModeButton;
