import React from 'react';
import { connect } from 'react-redux';
import { Comment as SemanticComment } from 'semantic-ui-react';

import type { ConnectedProps } from 'react-redux';
import type { Dispatch } from 'redux';

import ChatTypingStatus from '../Case/ChatTypingStatus';
import AutomaticComment from '../Comment/AutomaticComment';
import HelperComment from '../Comment/HelperComment';
import UserComment from '../Comment/UserComment';
import UserCommentSingleLine from '../Comment/UserCommentSingleLine';
import ChannelType from '../CommentIconContent/ChannelType';
import CommentsAccordion from './CommentsAccordion';
import FeatureFlags from 'src/api/FeatureFlags';
import { setActiveSingleLineComment } from 'src/reducers/commentsMenuReducer';
import { Direction } from 'src/types/Sorting';
import { Roles } from 'src/types/User';
import { commentOutImages } from 'src/Utilities/parseUtils';
import { sortComments } from 'src/Utilities/sortComments';

import type { Channel } from 'src/types/Channel';
import type { State } from 'src/types/initialState';
import type { Comment, Ticket } from 'src/types/Ticket';
import type { SenderEmail, TicketType } from 'src/types/TicketType';
import type { PersonalData, User } from 'src/types/User';

interface DiscussionProps extends ConnectedProps<typeof connector> {
  comments: Comment[];
  users: User[];
  userData: PersonalData;
  task: Ticket;
  taskId: string;
  channels: Channel[];
  senderEmails: SenderEmail[];
  ticketTypes: TicketType[];
  direction: Direction;
}

interface DiscussionState {
  isCommentExpanded: boolean;
  chatTyping: boolean;
}

class Discussion extends React.Component<DiscussionProps, DiscussionState> {
  private messagesEnd = React.createRef<HTMLDivElement>();

  constructor(props: DiscussionProps) {
    super(props);
    this.state = {
      isCommentExpanded: false,
      chatTyping: false
    };
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps: DiscussionProps) {
    if (this.props.comments.length !== prevProps.comments.length) {
      setTimeout(() => {
        this.scrollToBottom();
      }, 750);
    }
  }

  private scrollToBottom = () => {
    const { channel } = this.props.task;
    if (
      this.messagesEnd !== null &&
      Roles.isChatAnchored(this.props.userData) &&
      [ChannelType.Chat, ChannelType.Giosg].includes(channel)
    ) {
      this.messagesEnd.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  private getComments = () => {
    const { comments, direction } = this.props;

    /**
     * Filter out helper comments from main discussion thread
     * And add them to the parent comment they belongs to
     */
    const filteredComments = comments.filter(
      // AI Functions allow for ticket-level helper comments
      (comment) => comment.type !== 'helper' || (comment.type === 'helper' && !comment.metaData?.parentCommentId)
    );
    let sortedComments = sortComments(filteredComments);

    const suggestionComments = comments.filter((comment) => {
      return comment.type === 'suggestion';
    });

    const appendableSuggestionComments = suggestionComments.filter((suComment) => {
      return (
        sortedComments.find((comment) => {
          return suComment.metaData?.responseTo === comment.id;
        }) !== undefined
      );
    });

    sortedComments = sortedComments.filter((sComment) => {
      return (
        appendableSuggestionComments.find((ASComment) => {
          return ASComment.id === sComment.id;
        }) === undefined
      );
    });

    sortedComments = direction === Direction.DESC ? sortedComments : sortedComments.reverse();

    const sortedAppendedComments = [] as Comment[];

    sortedComments.forEach((comment) => {
      sortedAppendedComments.push(comment);
      appendableSuggestionComments.forEach((ASComment) => {
        if (ASComment.metaData?.responseTo === comment.id) {
          sortedAppendedComments.push(ASComment);
        }
      });
    });
    return sortedAppendedComments;
  };

  /**
   * Previous div for the map of comments, nove here for more lisibility
   * typescript : any - already there, probably because comment can be : mail, sms, internal, fb etc...
   * @param comment
   * @param isLastComment
   * @param isLastExternalComment
   * @param botButtonClickedState
   */
  private renderUserComment = (
    comment: Comment,
    isLastComment: boolean,
    isLastExternalComment: boolean,
    botButtonClickedState: undefined | Comment,
    isList = true
  ) => {
    const usersFlattened = this.props.users.reduce((users, user) => {
      users[user.UID] = user;
      return users;
    }, {});

    const isDisableImagesFilter =
      isList &&
      (!FeatureFlags.isFlagOn('ENABLE_COMMENT_IMAGES') ||
        (FeatureFlags.isFlagOn('ENABLE_COMMENT_IMAGES_TOGGLE') &&
          (this.props.showImages === undefined
            ? !FeatureFlags.isFlagOn('ENABLE_COMMENT_IMAGES')
            : !this.props.showImages)));

    const commentProps = {
      ...comment,
      parsedContent: isDisableImagesFilter ? commentOutImages(comment.parsedContent) : comment.parsedContent,
      ...(comment.metaData?.html?.length &&
        isDisableImagesFilter && {
          metaData: { ...comment.metaData, html: commentOutImages(comment.metaData.html) }
        })
    };

    if (comment.type === 'automatic') {
      return (
        <AutomaticComment
          ticketTypes={this.props.ticketTypes}
          channels={this.props.channels}
          usersData={this.props.users}
          attachments={this.props.task.attachments}
          contentType={this.props.task.type}
          user={usersFlattened[comment.createdByUser]}
          {...commentProps}
        />
      );
    }

    if (comment.type === 'helper') {
      return <HelperComment comment={comment} />;
    }

    return isList && this.props.singleLineComments ? (
      <UserCommentSingleLine
        isLastComment={isLastComment}
        isLastExternalComment={isLastExternalComment}
        task={this.props.task}
        ticketTypes={this.props.ticketTypes}
        senderEmails={this.props.senderEmails}
        user={usersFlattened[comment.createdByUser]}
        channels={this.props.channels}
        taskId={this.props.taskId}
        key={comment.id}
        botButtonClickedState={botButtonClickedState}
        {...commentProps}
      />
    ) : (
      <UserComment
        isLastComment={isLastComment}
        isLastExternalComment={isLastExternalComment}
        task={this.props.task}
        ticketTypes={this.props.ticketTypes}
        senderEmails={this.props.senderEmails}
        user={usersFlattened[comment.createdByUser]}
        channels={this.props.channels}
        taskId={this.props.taskId}
        key={comment.id}
        botButtonClickedState={botButtonClickedState}
        isCommentPreview={!isList}
        {...commentProps}
      />
    );
  };

  private isChatTicket(channel: number): boolean {
    switch (channel) {
      case ChannelType.Chat:
      case ChannelType.Giosg:
        return true;
    }

    return false;
  }

  render() {
    const comments = this.getComments();

    return this.props.singleLineComments ? (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <SemanticComment.Group style={{ marginTop: '0px', minWidth: '350px', width: '350px' }} threaded>
          <div className="discussionPanel">
            <CommentsAccordion
              comments={comments}
              direction={this.props.direction}
              renderUserComment={this.renderUserComment}
              disableCollapsing={
                this.props.task.channel === ChannelType.Chat ||
                this.props.task.channel === ChannelType.Giosg ||
                comments.length <= 4
              }
              setActiveSingleLineComment={this.props.setActiveSingleLineComment}
              activeComment={this.props.activeSingleLineComment}
              singleLineComments={this.props.singleLineComments}
            />

            <div ref={this.messagesEnd} />
          </div>

          {this.isChatTicket(this.props.task.channel) && <ChatTypingStatus ticketId={this.props.task.id} />}
        </SemanticComment.Group>
        <SemanticComment.Group style={{ maxWidth: '100%', flexGrow: 1, marginTop: '0px' }}>
          <SemanticComment
            className={`singleLineCommentsPreview lightGrey ${comments.find((c: any) => c.type === 'suggestion' && c.id === comments[0].id) ? 'suggestionComment' : ''}`}
          >
            {this.renderUserComment(
              (this.props.activeSingleLineComment &&
                comments.find((c) => c.id === this.props.activeSingleLineComment)) ||
                comments[0],
              false,
              false,
              undefined,
              false
            )}
          </SemanticComment>
        </SemanticComment.Group>
      </div>
    ) : (
      <SemanticComment.Group style={{ marginTop: '0px', maxWidth: '100%', width: '100%' }} threaded>
        <div className="discussionPanel">
          <CommentsAccordion
            comments={comments}
            direction={this.props.direction}
            renderUserComment={this.renderUserComment}
            disableCollapsing={
              this.props.task.channel === ChannelType.Chat ||
              this.props.task.channel === ChannelType.Giosg ||
              comments.length <= 4
            }
          />

          <div ref={this.messagesEnd} />
        </div>

        {this.isChatTicket(this.props.task.channel) && <ChatTypingStatus ticketId={this.props.task.id} />}
      </SemanticComment.Group>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setActiveSingleLineComment: (activeComment?: string) => dispatch(setActiveSingleLineComment(activeComment))
});

const mapStateToProps = (state: State) => ({
  showImages: state.commentsMenu.showImages,
  singleLineComments: state.commentsMenu.singleLineComments,
  activeSingleLineComment: state.commentsMenu.activeSingleLineComment,
  userData: state.userData
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Discussion);
