import React from 'react';

import type { FC } from 'react';

import ErrorBoundary from 'src/ErrorBoundary';
import MenuDropdown from 'src/MenuDropdown';
import MobileLayout from './Mobile/MobileLayout';
import DesktopLayout from './Desktop/DesktopLayout';
import ProfileDropdown from 'src/ProfileDropdown';

interface Props {
  mobileMode: boolean;
  children?: React.ReactNode;
}

export const LeftSideMenuDropdown = () => (
  <ErrorBoundary>
    <MenuDropdown />
  </ErrorBoundary>
);

export const RightSideProfileDropdown = () => (
  <ErrorBoundary>
    <ProfileDropdown />
  </ErrorBoundary>
);

const Layout: FC<Props> = ({ mobileMode, children }) => {  
  if (mobileMode){
    return <MobileLayout children={children} />
  } else {
    return <DesktopLayout children={children} />
  }
};

export default Layout;
