import React, { useState } from 'react';
import { Route } from 'react-router-dom';

import type { ReactNode } from 'react';
import type { RouteComponentProps } from 'react-router-dom';

import { LayoutLeftMenu } from '../LayoutLeftMenu';
import { FocusModeProvider } from 'src/Components/FocusMode/FocusModeContext';
import ProfileDropdown from 'src/ProfileDropdown';

import './DesktopLayout.css';

import { useSelector } from 'react-redux';

import { CommandPalette } from './CommandPalette';
import { DesktopLayoutRight } from './DesktopLayoutRight';
import { ListOfPresetFilters } from './ListOfPresetFilters';
import UnifiedMenuAndFilters from './UnifiedMenuAndFilters';
import FeatureFlags from 'src/api/FeatureFlags';
import PhoneButtonsFloating from 'src/Components/Case/PhoneButtonsFloating';
import ActivateFeatureModal from 'src/Components/generic/ActivateFeatureModal';
import EntityViewer from 'src/Components/Management/Entities/EntityViewer';
import TicketsSidebar from 'src/Components/Sidebar/TicketsSidebar';
import SingleLineTicketView from 'src/Components/ticketList/SingleLineTicketView/SingleLineTicketView';
import CaseContainer from 'src/containers/CaseContainer';
import InfopageCaseContainer from 'src/containers/InfopageCaseContainer';
import SettingsContainer from 'src/containers/SettingsContainer';
import urlActionContainer from 'src/containers/urlActionContainer';
import ReportingPage from 'src/ReportingPage';
import { SINGLE_LINE_VIEW_PATH } from 'src/Utilities/constants';

import type { State } from 'src/types/initialState';

const _App = (props: any) => (
  <FocusModeProvider>
    <div className="layout-desktop">{props.children}</div>
  </FocusModeProvider>
);

const _LayoutTopBar = (props: any) => (
  <div className="layout-desktop-topmenubar">
    <div className="layout-desktop-topmenubar-logo">
      <h1>Eeedo Desk 2024</h1>
    </div>
    <div className="layout-desktop-topmenubar-search">
      <CommandPalette />
    </div>
    <div className="layout-desktop-topmenubar-profile">
      <ProfileDropdown />
    </div>
  </div>
);

const _LayoutColumns = (props: any) => (
  <div className="layout-desktop-columns">
    {props.children.map((child: ReactNode) => (
      <>{child}</>
    ))}
  </div>
);
const _LayoutSideMenu = (props: any) => <LayoutLeftMenu />;

const _LayoutFilters = (props: any) => <ListOfPresetFilters />;

const _LayoutTicketList = () => {
  const { pathname, key } = useSelector<State, RouteComponentProps['location']>((state) => state.router.location);

  return (
    <div className="layout-desktop-ticketlist">
      <TicketsSidebar key={`${pathname}-${key}`} />
    </div>
  );
};
// TODO
const mobileMode = false;

const _LayoutContents = (props: any) => (
  <div className="layout-desktop-contents">
    <Route
      path="/case/:id"
      exact={true}
      render={(routeProps) => <CaseContainer {...routeProps} key={routeProps.match.params.id} />}
    />
    <Route path={SINGLE_LINE_VIEW_PATH} exact render={(routeProps) => <SingleLineTicketView {...routeProps} />} />
    <Route path="/reporting" component={ReportingPage} exact={true} />
    <Route
      path={['/settings/:pageName', '/settings']}
      render={(...props) => <SettingsContainer noMenuContainer={false} {...(props as any)} />}
    />
    {FeatureFlags.isFlagOn('ENABLE_ENTITY_VIEWER') && <Route path="/entities/" component={EntityViewer} />}
    {!mobileMode && FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') && (
      <Route
        path="/infopage/:id"
        render={(routeProps) => <InfopageCaseContainer {...routeProps} key={routeProps.match.params.id} />}
      />
    )}
    {!FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') && <Route path="/feature" component={ActivateFeatureModal} />}

    <Route path="/actions/:type" component={urlActionContainer} />
  </div>
);

const _LayoutFloating = () => <PhoneButtonsFloating />;

const _LayoutRightMenu = (props: any) => (
  <div className="layout-desktop-right-side-menu">
    <DesktopLayoutRight />
  </div>
);

const _UnifiedMenuAndFilters = () => <UnifiedMenuAndFilters />;

// const initialLayout = {
//   leftMenu: {
//     isMinimised: true
//   },
//   filters: {
//     isMinimised: true
//   },
//   lists: {
//     isMinimised: false
//   },
//   contents: {
//     isMinimised: false
//   },
//   rightMenu: {
//     isMinised: false
//   }
// };

const DesktopLayout = (props: { children: ReactNode }) => {
  const [isUnified, setIsUnified] = useState(true);
  // const [layout, setLayout] = useState(initialLayout);
  return (
    <_App>
      <button onClick={() => setIsUnified(!isUnified)}>
        Switch between Unified menu and Menu + Filter presets separately
      </button>
      <_LayoutTopBar />
      <_LayoutColumns>
        {isUnified && <_UnifiedMenuAndFilters />}
        {!isUnified && <_LayoutSideMenu />}
        {!isUnified && <_LayoutFilters />}
        <_LayoutTicketList />
        <_LayoutContents />
        <_LayoutRightMenu />
      </_LayoutColumns>
      <_LayoutFloating />
    </_App>
  );
};

// return (
//   <div className='wrapper'>
//     <Grid
//       verticalAlign="middle"
//       className="main-topbar"
//       style={{
//         ...(!!getUrlSearchParam('hideNavigation') && { display: 'none' }),
//       }}
//     >
//       <Grid.Row>
//         <Grid.Column floated="left" verticalAlign="middle">
//           <LeftSideMenuDropdown />
//         </Grid.Column>
//         <Grid.Column width={3} floated="left" textAlign="left">
//           <div className="logo" />
//         </Grid.Column>
//         <Grid.Column computer={8} tablet={4} mobile={1} />
//         <Grid.Column width={4} floated="right" textAlign="right">
//           <Grid>
//             <Grid.Row columns={2}>
//               <Grid.Column width={12} verticalAlign="middle" style={{ color: 'white' }} />
//                 <Grid.Column width={4}>
//                   <RightSideProfileDropdown />
//                 </Grid.Column>
//             </Grid.Row>
//           </Grid>
//         </Grid.Column>
//       </Grid.Row>
//     </Grid>
//     <ErrorBoundary>
//       <div className='main'>
//         {/* {props.children} */}
//       </div>
//     </ErrorBoundary>
//   </div>
// )

export default DesktopLayout;
