import React from 'react';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import type { ReactNode } from 'react';
import type { SemanticICONS } from 'semantic-ui-react';

import FocumModeButton from 'src/Components/FocusMode/FocusModeButton';

interface MenuItem {
  icon: SemanticICONS;
  text: string;
  href?: string;
}

const menuItems: MenuItem[] = [
  {
    icon: 'home',
    text: 'Main view',
    href: '/'
  },
  {
    icon: 'book',
    text: 'Knowledge base',
    href: '/infopage'
  },
  {
    icon: 'external square alternate',
    text: 'Export data'
  },
  {
    icon: 'chart bar',
    text: 'Reporting',
    href: '/reporting'
  },
  {
    icon: 'cogs',
    text: 'Settings',
    href: '/settings'
  }
];

export const LayoutLeftMenu = () => {
  // const [isMinimised, setIsMinimised] = useState(true);

  const wrapWithLink = (item: MenuItem, node: ReactNode) => {
    return (
      <Link to={item.href!} className="dropdown-link">
        {node}
      </Link>
    );
  };

  const Item = (props: { item: MenuItem }) => {
    const node = (
      <div className="layout-desktop-left-side-menu-item">
        <Icon size="large" name={props.item.icon} />
        {/* {isMinimised && (<div className="layout-desktop-left-side-menu-item-text">{props.item.text}</div>)} */}
        <div className="layout-desktop-left-side-menu-item-text">{props.item.text}</div>
      </div>
    );

    return props.item.href ? wrapWithLink(props.item, node) : node;
  };

  return (
    <Translation>
      {() => (
        <div
          className="layout-desktop-left-side-menu"
          // onPointerEnter={() => setIsMinimised(false)}
          // onPointerLeave={() => setIsMinimised(true)}
        >
          <div>
            {menuItems.map((item) => (
              <Item item={item} />
            ))}
          </div>

          <div style={{ marginTop: 'auto', marginBottom: '15px' }}>
            <FocumModeButton />
          </div>
        </div>
      )}
    </Translation>
  );
};
