import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

import type { ComponentProps, FC } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { State } from 'src/types/initialState';

interface PriorityDropdownProps extends ConnectedProps<typeof connector> {
  id?: string;
  value?: number;
  error?: ComponentProps<typeof Dropdown>['error'];

  onChange?: ComponentProps<typeof Dropdown>['onChange'];
}

const PriorityDropdown: FC<PriorityDropdownProps> = ({ id, value, error, userData, ticketPriorities, onChange }) => {
  const { t } = useTranslation();

  const priorities = useMemo(
    () =>
      ticketPriorities
        .map((ticketPriority) => ({
          ...ticketPriority,
          text: t(ticketPriority.text)
        }))
        .filter((priority) => priority.active || priority.value === value)
        .map((priority) => ({
          text: priority.text,
          value: priority.value,
          icon: priority.icon,
          color: priority.color,
          disabled: !priority.active
        })),
    [value, ticketPriorities]
  );

  return (
    <Dropdown
      id={id}
      selection
      error={error}
      value={value}
      selectOnBlur={false}
      options={priorities}
      disabled={!userData.permissions.includes('updateContent')}
      onChange={onChange}
    />
  );
};

const connector = connect((state: State) => ({
  userData: state.userData,
  ticketPriorities: state.ticketPriorities
}));

export default connector(PriorityDropdown);
