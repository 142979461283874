import type { ListArticle } from './Articles';
import type { Ticket } from './Ticket';

export enum FloatingWindowTypes {
  AIAssistant = 'aIAssistant',
  Phone = 'phone',
  Infopage = 'infopage',
  Task = 'task'
}

export enum FloatingWindowPhoneIntegrations {
  oc = 'oc',
  ring = 'ring',
  enreach = 'enreach',
  mittel = 'mittel'
}

export interface FloatingWindowState<T = FloatingWindowTypes> {
  id: string;
  title: string;
  content?: string;
  fold?: boolean;
  width?: number;
  height?: number;
  x?: number;
  y?: number;
  type: T;
  data?: T extends FloatingWindowTypes.Task ? Ticket : T extends FloatingWindowTypes.Infopage ? ListArticle : Ticket;
}

export type FloatingWindowsState = FloatingWindowState[];
