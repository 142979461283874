import React from 'react';

import TicketListTab from '../ticketList/TicketListTab';
import InfopageListTabBarContainer from '../../containers/InfopageListTabBarContainer';
import ErrorBoundary from '../../ErrorBoundary';
import type { PersonalData } from '../../types/User';

interface IProps {
  userPersonalData: PersonalData;
}

const InfoPagesSidebar = (props: IProps) => {
  if (props.userPersonalData.permissions.includes('showTicketlist')) {
    return (
      <div className="sideBar">
        <ErrorBoundary>
          <InfopageListTabBarContainer />
        </ErrorBoundary>

        <div className="flexSideView">
          <ErrorBoundary>
            <TicketListTab contentType="infopages" noDefaultDates />
          </ErrorBoundary>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default InfoPagesSidebar;
