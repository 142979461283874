import type { SemanticICONS } from 'semantic-ui-react';

import { ChannelType } from 'src/types/Channel';

export const SINGLE_LINE_VIEW_PATH = '/list';

export const statusTranslationsMap = {
  todo: 'CASE_STATUS_TODO',
  doing: 'CASE_STATUS_DOING',
  done: 'CASE_STATUS_DONE'
};

export const channelIconsMap: Partial<Record<ChannelType, SemanticICONS>> = {
  [ChannelType.Internal]: 'sticky note',
  [ChannelType.Email]: 'mail',
  [ChannelType.SecureEmail]: 'lock',
  [ChannelType.Chat]: 'comments outline',
  [ChannelType.Sms]: 'mobile alternate',
  [ChannelType.Facebook]: 'facebook messenger',
  [ChannelType.Giosg]: 'comments outline',
  [ChannelType.CustomerPortal]: 'comments outline',
  [ChannelType.Tampuuri]: 'comments outline',
  [ChannelType.WhatsApp]: 'whatsapp',
  [ChannelType.Salesforce]: 'cloud',
  [ChannelType.D365]: 'microsoft',
  [ChannelType.Eezy]: 'cloud',
  [ChannelType.ViestiTytti]: 'cloud',
  [ChannelType.Instagram]: 'instagram',
  [ChannelType.Phone]: 'phone',
  [ChannelType.Webform]: 'globe',
  [ChannelType.Other]: 'question circle outline'
};
