import { createSlice } from '@reduxjs/toolkit';

import type { ResponseTemplate } from '@eeedo/types';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { Template } from 'src/types/Template';
import type { TitleTemplate } from 'src/types/TitleTemplate';

const initialState = {
  titleTemplates: [] as TitleTemplate[],
  responseTemplates: [] as ResponseTemplate[],
  templates: [] as Template[]
};

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    loadResponseTemplatesSuccess: (state, action: PayloadAction<ResponseTemplate[]>) => {
      state.responseTemplates = action.payload;
    },
    loadTitleTemplatesSuccess: (state, action: PayloadAction<TitleTemplate[]>) => {
      state.titleTemplates = action.payload;
    },
    loadTemplatesSuccess: (state, action: PayloadAction<Template[]>) => {
      state.templates = action.payload;
    }
  }
});

export const { loadResponseTemplatesSuccess, loadTemplatesSuccess, loadTitleTemplatesSuccess } = templatesSlice.actions;

export default templatesSlice.reducer;
