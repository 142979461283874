import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { Button, Form, Icon } from 'semantic-ui-react';

import type { ChangeEvent, ComponentProps, SyntheticEvent } from 'react';
import type { ConnectedProps } from 'react-redux';

import ArticleListItem from './ArticleListItem/ArticleListItem';
import * as styles from './SuggestedArticles.style';
import KBApi from 'src/api/KBApi';
import AccordionHeader from 'src/Components/Case/AccordionHeader';
import ErrorBoundary from 'src/ErrorBoundary';
import { setArticles } from 'src/reducers/articlesReducer';

import type { ListArticle } from 'src/types/Articles';
import type { State } from 'src/types/initialState';

import './SuggestedArticles.css';

interface SuggestedArticlesProps extends ConnectedProps<typeof connector> {
  id: string;
}

const SuggestedArticles = ({
  id,
  search,
  allTags,
  suggested,
  ticketId,
  categories,
  ticketTags,
  setArticles
}: SuggestedArticlesProps) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const [term, setTerm] = useState('');
  const [viewId, setViewId] = useState<string | undefined>();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);

  const defaultTag = allTags.find((t) => t.name === 'default');
  const removeDefaultTag = (a: ListArticle) => ({
    ...a,
    tags: a.tags?.filter((tagId) => tagId !== defaultTag?.id) ?? []
  });
  const getRelatedArticles = (...args: Parameters<typeof KBApi.getRelatedArticles>) =>
    KBApi.getRelatedArticles(...args)
      .then(({ hasMore, articles }) => {
        setHasMore(hasMore);
        const loadedArticles = articles.map(removeDefaultTag);
        const isExistedArticles = !!suggested && !!args[0].page && args[0].page > 1;
        setArticles({
          ticketId,
          type: 'suggested',
          articles: isExistedArticles ? suggested.concat(loadedArticles) : loadedArticles
        });
      })
      .catch((e) => {
        if (e.name === 'CanceledError') {
          console.log('SuggestedArticles component has been cancelled HTTP request on unmount', e);
          return;
        }

        throw e;
      });

  const searchArticles = (...args: Parameters<typeof KBApi.searchArticles>) =>
    KBApi.searchArticles(...args).then(({ hasMore, articles }) => {
      setHasMore(hasMore);
      const loadedArticles = articles.map(removeDefaultTag);
      setArticles({ ticketId, type: 'search', articles: args[2] > 1 ? search.concat(loadedArticles) : loadedArticles });
    });

  useEffect(() => {
    KBApi.getSettings()
      .then((response) => response?.views)
      .then((views) => {
        const category = categories.find((c) => c.tags.some((tId) => ticketTags.includes(tId)));

        if (views && category?.id) {
          const hash = views.find((v) => v.publishedCategories.includes(category.id))?.hash;
          setViewId(hash);
        }
      });
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    if (ticketId) {
      setHasMore(false);
      setPage(1);
      getRelatedArticles({ ticketId, signal: controller.signal, page: 1 });
    }

    return () => {
      controller.abort();
    };
  }, [ticketId, JSON.stringify(ticketTags)]);

  const onToggle = useCallback(() => {
    setToggle(!toggle);
  }, [toggle]);

  const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTerm(e.target.value);
  }, []);

  const onSearchClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      initiateSearch(false);
    },
    [term, toggle, search]
  );

  const initiateSearch = useCallback(
    (isLoadMore: boolean) => {
      let newPage = 1;
      if (isLoadMore) {
        newPage = page + 1;
      }
      setPage(newPage);
      setHasMore(false);
      if (term && ticketId) {
        toggle ? searchArticles(ticketId, term, newPage) : getRelatedArticles({ ticketId, term, page: newPage });
      }
    },
    [term, toggle, setPage, setHasMore]
  );

  const onCloseClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setTerm('');
      if (ticketId) {
        setHasMore(false);
        setPage(1);
        toggle ? setArticles({ ticketId, type: 'search', articles: [] }) : getRelatedArticles({ ticketId, page: 1 });
      }
    },
    [term]
  );

  const showMore = useCallback(() => {
    if (term) {
      initiateSearch(true);
    } else {
      const newPage = page + 1;
      setPage(newPage);
      getRelatedArticles({ ticketId, page: newPage });
    }
  }, [ticketId, term, page]);

  const items = useMemo(() => Object.values((toggle ? search : suggested) ?? []), [toggle, search, suggested]);

  const cache = new CellMeasurerCache({ defaultHeight: 10, fixedWidth: true });

  const rowRenderer: ComponentProps<typeof List>['rowRenderer'] = ({ index, key, style, parent }) => {
    const item = items[index];
    const isOpen = index === selectedIndex;

    return (
      <CellMeasurer key={key} columnIndex={0} parent={parent} rowIndex={index} cache={cache}>
        <div style={style}>
          <ArticleListItem
            term={term}
            item={item}
            viewId={viewId}
            open={isOpen}
            onClick={() => {
              setSelectedIndex(isOpen ? undefined : index);
            }}
          />
        </div>
      </CellMeasurer>
    );
  };

  return (
    <ErrorBoundary>
      <AccordionHeader as="h4" id={id} title={t('widgets.suggested_articles.heading')} active={true} icon="info circle">
        <Form>
          <Form.Group style={styles.searchWrapper}>
            <Form.Input fluid width="16" action style={styles.searchInput}>
              <input onChange={onInputChange} value={term} minLength={3} />
              <Button icon primary labelPosition="left" onClick={onSearchClick} disabled={term.length < 4}>
                {t('widgets.suggested_articles.search')}
                <Icon name={term ? 'close' : 'search'} link={!!term} onClick={term ? onCloseClick : undefined} />
              </Button>
            </Form.Input>
            <Form.Checkbox
              toggle
              width={'4'}
              label={toggle ? t('widgets.suggested_articles.all') : t('widgets.suggested_articles.suggested')}
              checked={toggle}
              onChange={onToggle}
            />
          </Form.Group>
        </Form>
        <div className="scrollable-container" style={{ width: '100%' }}>
          <AutoSizer>
            {({ width }) => (
              <List
                autoHeight={true}
                width={width}
                height={items.length ? 480 : 0}
                rowCount={items.length}
                rowHeight={cache.rowHeight}
                rowRenderer={rowRenderer}
                deferredMeasurementCache={cache}
              />
            )}
          </AutoSizer>
        </div>
        <div style={styles.bottomSectionWrapper}>
          {items.length > 0 && (
            <Button disabled={hasMore === false} onClick={showMore}>
              {t('GENERAL_SHOW_MORE')}
            </Button>
          )}
          {!items?.length && t('GENERAL_SEARCH_NO_RESULTS')}
        </div>
      </AccordionHeader>
    </ErrorBoundary>
  );
};

const connector = connect(
  (state: State) => {
    const ticketId = state.activeTicketTab;
    if (!ticketId) return null;

    const currentTicket = state.detailedTickets.find((t) => t.id === ticketId);
    return {
      ticketId,
      allTags: state.tags,
      search: state.articles.search[ticketId],
      suggested: state.articles.suggested[ticketId],
      ticketTags: currentTicket?.tags?.map((t) => parseInt(t.substring(3), 10)) ?? [],
      categories: state.categories
    };
  },
  { setArticles }
);

export default connector(SuggestedArticles);
