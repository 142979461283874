import React from 'react'
import type { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useGesture } from 'react-with-gesture';
import type { GestureState } from 'react-with-gesture';

import { swipeLeft, swipeRight } from '../../../actions/swipeActions';
import { history } from '../../../reducers/browserHistory';
import { Grid } from 'semantic-ui-react';
import ErrorBoundary from 'src/ErrorBoundary';
import { LeftSideMenuDropdown, RightSideProfileDropdown } from '../Layouts';
import { getUrlSearchParam } from 'src/Utilities/helper';

const MobileLayout = (props: { children: ReactNode }) => {
  console.log(props.children?.toLocaleString().substring(0,1));
  const dispatch = useDispatch();
  const [bindGestures] = useGesture({
    touch: true, // accept touch input
    mouse: true, // accept mouse input
    passive: { passive: true }, // event handler 3rd argument input, passive by default
    onDown: (state: GestureState) => {
      const deltaX = state.delta[0];
      console.debug('User swiped up');
      if (deltaX > 80) {
        console.debug('User swiped right');
        dispatch(swipeRight());
        history.push('/');
      } else if (deltaX < -80) {
        console.debug('User swiped left');
        dispatch(swipeLeft());
      } else {
        console.debug('User\'s swipe was too short');
      }
    }
  });
  
    return (
      <React.Fragment>
        <Grid
          verticalAlign="middle"
          className="main-topbar"
          style={{
            ...(!!getUrlSearchParam('hideNavigation') && { display: 'none' }),
            ...{ height: '85px', marginBottom: '0px' }
        }}>
            <Grid.Row>
            <Grid.Column className="left floated left aligned" style={{ width: '70px' }} verticalAlign="middle">
              <LeftSideMenuDropdown />
            </Grid.Column>
            <Grid.Column className="right floated right aligned column" style={{ width: '79px' }}>
              <RightSideProfileDropdown />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ErrorBoundary>
          <div className='mainMobile' {...bindGestures()}>
            {/* {props.children} */}
          </div>
        </ErrorBoundary>
    </React.Fragment>
  )
}

export default MobileLayout;