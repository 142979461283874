import iziToast from 'izitoast';
import { get } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Comment, Icon, Popup } from 'semantic-ui-react';
import { stripHtml } from 'string-strip-html';

import type { FC } from 'react';

import ChannelType from '../CommentIconContent/ChannelType';
import UserAvatar from '../User/UserAvatar';
import UserPopup from '../User/UserPopup';
import CommentText from './CommentText';
import ExternalCommentPopup from './ExternalCommentPopup';
import HelperComment from './HelperComment';
import { getCommentSender } from 'src/Components/Utilities/comments';
import { iconName } from 'src/Utilities/iconName';

import type { Channel } from 'src/types/Channel';
import type { Ticket, Comment as TicketComment } from 'src/types/Ticket';
import type { SenderEmail, TicketType } from 'src/types/TicketType';
import type { User } from 'src/types/User';

import './UserComment.css';

import CommentIcon from '../CommentIcon/CommentIcon';
import CommentIconContent from '../CommentIconContent/CommentIconContent';
import ErrorBoundary from 'src/ErrorBoundary';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';

// TODO: css truncating?
const COMMENT_MAX_SIZE = 35;

interface UserCommentSingleLineProps extends TicketComment {
  user: User;
  task: Ticket;
  taskId: string;
  channels: Channel[];
  title: string | null;
  senderEmails: SenderEmail[];
  ticketTypes: TicketType[];
  botButtonClickedState: undefined | TicketComment;
  isLastComment?: boolean;
  isLastExternalComment?: boolean;
}

const UserCommentSingleLine: FC<UserCommentSingleLineProps> = (props) => {
  const { t } = useTranslation();
  const [isIntegratorUserReply, setIntegratorUserReply] = useState(false);
  const [chatComment, setChatComment] = useState(false);
  const [channelTypeName, setChannelTypeName] = useState<string>('');

  const stripedCommentContent = stripHtml(props.parsedContent || '').result;
  const truncatedCommentContent =
    stripedCommentContent.length > COMMENT_MAX_SIZE
      ? `${stripedCommentContent.substring(0, COMMENT_MAX_SIZE)}...`
      : stripedCommentContent;

  useEffect(() => {
    /**
     * Check if incoming comment comes from integrator
     */
    if (
      [ChannelType.Chat, ChannelType.Facebook, ChannelType.Giosg, ChannelType.CustomerPortal].includes(
        props.channel!
      ) &&
      props.direction === 'in' &&
      props.task.entities.length
    ) {
      const targetEntity = props.task.entities[0].data;

      if (targetEntity?.firstName || targetEntity?.lastName) {
        setIntegratorUserReply(true);
      }
    }
  }, [props.channel, props.direction, props.task.entities.length]);

  useEffect(() => {
    const channelTypeToName = {
      [ChannelType.Email]: 'email',
      [ChannelType.Sms]: 'sms',
      [ChannelType.Internal]: 'internal',
      [ChannelType.Chat]: 'chat',
      [ChannelType.Webform]: 'webform',
      [ChannelType.Other]: 'other'
    };

    try {
      setChannelTypeName(channelTypeToName[props.channel ?? ChannelType.Other]);
    } catch (_) {
      setChannelTypeName('other');
    }

    setChatComment(
      ['customer_closed_chat', 'agent_closed_chat', 'chat_was_closed_automatically', 'user_closed_chat'].some(
        (tag) => props.content?.indexOf(`[${tag}]`) === 0
      )
    );
  }, []);

  const getCustomerInfoFields = () =>
    props.ticketTypes
      .find((ticketType) => ticketType.name === props.task.taskType)
      ?.fieldSets?.find((fieldSet) => fieldSet.id === 'customerInfo')?.customerInfo ?? [];

  const getChannelData = (channelId: number) => {
    return props.channels.find((channel) => channel.id === channelId);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    iziToast.info({
      message: t('COPIED')
    });
  };

  const { user } = props;
  const channelData = getChannelData(props.channel!);
  const originalEntityData = get(props, ['metaData', 'originalEntity'], null);

  const helperComments = props.task.comments.filter(
    (comment) => comment.type === 'helper' && comment.metaData?.parentCommentId === parseInt(props.id.substring(3), 10)
  );

  let commentSender: string = getCommentSender({
    originalEntityData,
    channelData,
    user,
    metaData: props.metaData ?? null,
    direction: props.direction ?? null,
    ticketEntities: props.task.entities,
    customerInfoFields: getCustomerInfoFields()
  });

  if (['CHAT_ENTITY', 'FORM_ENTITY', 'TAMPUURI_ENTITY', 'UNKNOWN'].includes(commentSender)) {
    commentSender = t(`COMMENT_AUTHOR_${commentSender}`);
  }

  return (
    <Comment>
      {![ChannelType.Internal, ChannelType.Other].includes(props.channel!) && props.direction === 'in' ? (
        <ExternalCommentPopup
          channelId={props.channel!}
          commentId={props.id}
          metaData={props.metaData}
          ticketTypes={props.ticketTypes}
          channels={props.channels}
          title={props.title}
        >
          <div style={{ marginLeft: '0.3rem', float: 'left' }}>
            <Icon
              name={iconName(channelData, props.direction!)}
              className="icon-small"
              style={{ color: channelData?.color || '#000', fontSize: '1.55rem' }}
            />
          </div>
        </ExternalCommentPopup>
      ) : (
        <UserPopup UID={user.UID} botName={props.metaData?.bot?.name} disabled={isIntegratorUserReply}>
          <div style={{ float: 'left' }}>
            <div style={{ marginRight: '7px' }}>
              <UserAvatar user={user} size="35" />
            </div>
          </div>
        </UserPopup>
      )}
      <Comment.Content
        style={{ color: 'transparent', marginLeft: '3rem', paddingRight: '5px' }}
        className={`${channelTypeName}CommentIcon`}
      >
        <div style={{ display: 'flex' }}>
          <div className="userComment__metadataWrapper" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
            <Comment.Author as="a" onClick={() => copyToClipboard(commentSender)}>
              {isIntegratorUserReply ? (
                <>
                  <Icon name={'user'} />
                  {commentSender}
                </>
              ) : (
                commentSender
              )}
            </Comment.Author>
            <Comment.Metadata>
              <div>{getPrettyDate(props.created, { format: DATE_TIME_FORMAT })}</div>
              <div className={channelTypeName || 'other CommentIcon'}>
                <Popup
                  className="commentContentPopup"
                  position="top center"
                  trigger={
                    <i>
                      <CommentIcon
                        style={{
                          color: channelData?.color || ''
                        }}
                        channel={channelData}
                        originalDirection={props.direction!}
                      />
                    </i>
                  }
                  content={
                    <ErrorBoundary>
                      <CommentIconContent
                        channels={props.channels}
                        ticketTypes={props.ticketTypes}
                        channel={props.channel!}
                        title={props.title || null}
                        metaData={props.metaData!}
                        showSender
                      />
                    </ErrorBoundary>
                  }
                  on={'hover'}
                  hoverable={true}
                />
              </div>
            </Comment.Metadata>
          </div>
        </div>

        <CommentText
          parsedContent={truncatedCommentContent}
          isChatComment={chatComment}
          isCommentExpanded={false}
          isLastComment={!!props.isLastComment}
          botButtonClickedState={props.botButtonClickedState}
          metaData={props.metaData}
          type={props.type!}
          attachments={props.task.attachments}
          toggleExpand={() => {}}
        />
      </Comment.Content>
      {/* what to do here?*/}
      {helperComments.length ? (
        <Comment.Group className="discussionPanel__helpers">
          {helperComments.map((comment) => (
            <HelperComment comment={comment} key={comment.id} />
          ))}
        </Comment.Group>
      ) : null}
    </Comment>
  );
};

export default UserCommentSingleLine;
