import React, { createContext, useContext, useState } from 'react';

import type { FunctionComponent } from 'react';

interface FocusModeContextType {
  isFocusMode: boolean;

  toggleFocusMode: () => void;
}

const FocusModeContext = createContext<FocusModeContextType | undefined>(undefined);

export const useFocusMode = () => {
  const context = useContext(FocusModeContext);
  if (context === undefined) {
    throw new Error('useFocusMode must be used within a FocusModeProvider');
  }
  return context;
};

export const FocusModeProvider: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const [isFocusMode, setFocusMode] = useState<boolean>(false);

  const toggleFocusMode = () => setFocusMode((prevState) => !prevState);

  return <FocusModeContext.Provider value={{ isFocusMode, toggleFocusMode }}>{children}</FocusModeContext.Provider>;
};
