import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import ApiConfig from './api/ApiConfig';
import { publicHttpClient } from './Utilities/httpClients';
import moment from 'moment';
import { setHtmlLangAttribute } from './Utilities/helper';
import { getLanguages, setLanguage } from './api/Languages';

class Internationalization {
  static config = i18n.use(Backend).use(initReactI18next);

  static init = async () => {
    await Internationalization.config.init({
      // have a common namespace used around the full app
      ns: ['translations'],
      defaultNS: 'translations',
      fallbackLng: ApiConfig.getConfig().DEFAULT_LANGUAGE || 'en',
      debug: false,
      interpolation: {
        escapeValue: false // not needed for react!!
      },
      react: {
        useSuspense: true
      }
    });
  };

  static fetchTranslations = async (url: string) => {
    try {
      const transFile = await publicHttpClient.get(url);
      transFile.data.forEach((file: any) => {
        Internationalization.config.addResourceBundle(file.lanq, file.namespace, file.translations, true, true);
      });
    } catch (error) {
      console.log('Failed to load custom translations url', error);
      throw error;
    }
  };

  static getLanguages = () => {
    const langs = getLanguages();
    return langs.map((l) => ({
      key: l.id,
      value: l.id,
      flag: l.flag,
      text: l.name
    }));
  }

  static changeLanguage = (i18nInstance: any, lang: string) => {
    const language = `${lang}` || 'en';

    const correctI18nLanguage = getLanguages().find((l) => l.id === language);

    if (!correctI18nLanguage){
      return;
    }

    moment.locale(correctI18nLanguage.momentLocale);
    setHtmlLangAttribute(correctI18nLanguage.momentLocale);
    setLanguage(correctI18nLanguage.id);

    i18nInstance.changeLanguage(correctI18nLanguage.id);
  }
}

export default Internationalization;
