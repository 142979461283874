import type { Action, ActionCreator, Dispatch } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import CategoriesApi from '../api/CategoriesApi';
import { startAjaxCall } from './ajaxStatusActions';
import {
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  FETCH_CATEGORIES_SUCCESS,
  PATCH_CATEGORY_SUCCESS
} from './index';
import { initialRequestFailure, initialRequestSuccess } from './initActions';

import type { Category } from '../types/Category';

export const fetchCategoriesSuccess = (categories: Category[]) => ({ type: FETCH_CATEGORIES_SUCCESS, categories });
export const patchCategorySuccess = (category: Category) => ({ type: PATCH_CATEGORY_SUCCESS, category });
export const createCategorySuccess = (category: Category) => ({ type: CREATE_CATEGORY_SUCCESS, category });
export const deleteCategorySuccess = (categoryId: number) => ({ type: DELETE_CATEGORY_SUCCESS, categoryId });

export const fetchCategories: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> =
  () => async (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_CATEGORIES' }));

    try {
      const categories = await CategoriesApi.getCategories();
      dispatch(fetchCategoriesSuccess(categories));
      dispatch(initialRequestSuccess('categories'));
    } catch (error) {
      console.error('Failed to load categories', error);
      dispatch(initialRequestFailure('categories', error));
    }
  };

export const createCategory: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> =
  (payload: Partial<Category>) => async (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'CREATE_CATEGORY' }));

    try {
      const category = await CategoriesApi.createCategory(payload);
      dispatch(createCategorySuccess(category));
    } catch (error) {
      console.error('Failed to create category', error);
      throw error;
    }
  };

export const updateCategory: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> =
  (categoryId: number, payload: Partial<Category>) => async (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'UPDATE_CATEGORY' }));

    try {
      const category = await CategoriesApi.updateCategory(categoryId, payload);
      dispatch(patchCategorySuccess(category));
    } catch (error) {
      console.error('Failed to update category', error);
      throw error;
    }
  };

export const deleteCategory: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> =
  (categoryId: number) => async (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'DELETE_CATEGORY' }));

    try {
      await CategoriesApi.deleteCategory(categoryId);
      dispatch(deleteCategorySuccess(categoryId));
    } catch (error) {
      console.error('Failed to delete category', error);
      throw error;
    }
  };

export const attachTagToCategory: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> =
  (categoryId: number, tagId: number) => async (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'ATTACH_TAG_TO_CATEGORY' }));

    try {
      const category = await CategoriesApi.attachTagToCategory(categoryId, tagId);
      dispatch(patchCategorySuccess(category));
    } catch (error) {
      console.error('Failed to attach tag to category', error);
      throw error;
    }
  };

export const detachTagFromCategory: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> =
  (categoryId: number, tagId: number) => async (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'DETACH_TAG_FROM_CATEGORY' }));

    try {
      const category = await CategoriesApi.detachTagFromCategory(categoryId, tagId);
      dispatch(patchCategorySuccess(category));
    } catch (error) {
      console.error('Failed to detach tag from category', error);
      throw error;
    }
  };
