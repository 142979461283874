import iziToast from 'izitoast';
import { clone, cloneDeep, intersectionWith, isEqual } from 'lodash-es';
import { uniq } from 'ramda';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Dropdown, Form, Header, Icon, Loader, Message, Segment } from 'semantic-ui-react';

import type { Canceler } from 'axios';
import type { WithTranslation } from 'react-i18next';
import type { DropdownProps } from 'semantic-ui-react';

import CustomerResult from './CustomerResult';
import FeatureFlags from 'src/api/FeatureFlags';
import Info from 'src/Components/Case/Info/Info';
import { apiClient } from 'src/Utilities/httpClients';
import { buildSearchUrl } from 'src/Utilities/ticketTypes';

import type { Field, OnInfoSave } from 'src/types/Info';
import type { Entity, Ticket } from 'src/types/Ticket';
import type { FieldSet, TicketType } from 'src/types/TicketType';

import './AddCustomer.css';

// Imported for cancelToken
import axios from 'axios';

interface AddCustomerProps extends WithTranslation {
  fields: Field[];
  onSelect: (...args: any[]) => any;
  task: {
    id: Ticket['id'];
    taskType: Ticket['taskType'];
    entities: Ticket['entities'];
  };
  fieldSets: FieldSet[];
  ticketTypes: TicketType[];
  dropDownValue?: string;
  generalDisable?: boolean;

  changeEntity?: (entityName: string, id: string) => void;
}

interface AddCustomerState {
  isLoading: boolean;
  isResultsReady: boolean;
  results: Entity[];
  values: object;
  filledFields: string[];
  entityTypesFromField: string[] | undefined;
  enableButtons: boolean;
}

class AddCustomer extends React.Component<AddCustomerProps, AddCustomerState> {
  private cancelSearch: Canceler | null;
  constructor(props: AddCustomerProps) {
    super(props);

    this.cancelSearch = null;

    this.state = {
      isLoading: false,
      isResultsReady: false,
      results: [],
      values: {},
      filledFields: [],
      entityTypesFromField: undefined,
      enableButtons: false
    };
  }

  componentWillReceiveProps(nextProps: AddCustomerProps) {
    // If either task or task's type changes - cancel current search and set initialState
    if (
      this.props.task.id !== nextProps.task.id ||
      this.props.task.taskType !== nextProps.task.taskType ||
      this.props.dropDownValue !== nextProps.dropDownValue
    ) {
      if (this.cancelSearch !== null) {
        this.cancelSearch();
      }
      this.setState({
        results: [],
        filledFields: [],
        values: {},
        isLoading: false,
        isResultsReady: false,
        enableButtons: false
      });
    }
  }

  shouldComponentUpdate(nextProps: Readonly<AddCustomerProps>, nextState: Readonly<AddCustomerState>) {
    /**
     * Getting rid of passed function
     * Best way is to memoize them but not that effective because of their dependencies on another props
     * Future implementation: refactor those function with useCallback
     */
    const filteredProps = clone(this.props);
    const filteredNextProps = clone(nextProps);
    for (const [key, value] of Object.entries(this.props)) {
      if (typeof value === 'function') {
        delete filteredProps[key];
        delete filteredNextProps[key];
      }
    }

    if (isEqual(filteredProps, filteredNextProps) && isEqual(this.state, nextState)) {
      return false;
    }
    return true;
  }

  private entityChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const entities = cloneDeep(this.props.fieldSets);
    const entity = entities.find((entity) => entity.displayName === data.value);
    if (this.props.changeEntity !== undefined && entity !== undefined) {
      this.props.changeEntity(String(data.value), entity.id);
    }
  };

  // TODO: refactor to use object of params under Info.tsx callback (too much unnecessary parameters)
  private fieldSave: OnInfoSave = (...args) => {
    const [fieldName, valueToSave, object, , , , , , , entityTypeFromField] = args;
    const values = Object.assign({}, this.state.values);

    if (object === false) {
      values[fieldName] = valueToSave;
      this.handleFilledFieldsState(fieldName, valueToSave);
    } else {
      if (values[object] === undefined) {
        values[object] = {};
      }
      values[object][fieldName] = valueToSave;
      this.handleFilledFieldsState(`${object}.${fieldName}`, valueToSave);
    }

    const taskTicketType = this.props.ticketTypes.find((ticketType) => ticketType.name === this.props.task.taskType);
    const taskEntityTypes = taskTicketType?.entityRouting?.map(({ entityType }) => entityType);

    // TODO: this cumbersome expression is temporary here and should be removed
    // as soon as we change the ticketTypes approach
    const entityTypesFromField = uniq([...(taskEntityTypes ?? []), ...(entityTypeFromField ?? [])].filter(Boolean));

    this.setState({ values, entityTypesFromField });
  };

  private createNewCustomer = async () => {
    const activeFieldSet = this.props.fieldSets.find((fieldset) => fieldset.displayName === this.props?.dropDownValue);
    const createEntityType = activeFieldSet?.createEntity;
    const postAttachmentOperation = activeFieldSet?.postAttachmentOperation;

    this.setState(
      {
        isLoading: true
      },
      async () => {
        const body = {
          ...this.state.values,
          postAttachmentOperation
        };
        if (createEntityType) {
          body['entityType'] = createEntityType;
        }

        await this.props.onSelect(body);

        this.setState({
          isLoading: false,
          isResultsReady: false,
          filledFields: [],
          results: [],
          values: {},
          enableButtons: false
        });
      }
    );
  };

  private handleResultSelect = (result: Entity) => {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.props.onSelect({
          _id: result._id,
          _type: result._type,
          postAttachmentOperation: result.postAttachmentOperation
        });
        this.setState({
          isLoading: false,
          isResultsReady: false,
          results: [],
          filledFields: [],
          values: {}
        });
      }
    );
  };

  private performUserSearch = (type: 'click' | 'blur') => {
    const { t } = this.props;
    if (type === 'blur' && !FeatureFlags.isFlagOn('ENABLE_ENTITY_SEARCH_ON_BLUR')) {
      return;
    }

    const entityTypesOfFieldset = this.props.fieldSets.find(
      (field: FieldSet) => field.displayName === this.props?.dropDownValue
    )?.entityTypes;

    const params = { entityTypes: entityTypesOfFieldset || this.state.entityTypesFromField };
    this.state.filledFields.forEach((field) => {
      if (this.state[field] !== '') {
        params[field] = typeof this.state[field] === 'string' ? this.state[field].trim() : this.state[field];
      }
    });

    const searchUrl = buildSearchUrl(params);

    this.setState({ isLoading: true }, () => {
      apiClient
        .get(searchUrl, {
          cancelToken: new axios.CancelToken((cancelFunction) => {
            this.cancelSearch = cancelFunction;
          })
        })
        .then((response) => {
          iziToast.success({
            message: t('SEARCH_RESULTS', { amount: response.data.length }),
            icon: 'icon check'
          });
          const results = response.data || [];
          this.setState({
            results,
            isLoading: false,
            isResultsReady: true
          });
        })
        .catch((error) => {
          iziToast.error({
            message: t('SEARCH_RESULTS_ERROR'),
            timeout: 3000,
            position: 'bottomRight'
          });
          console.error('Error while searcing entities', error);
          this.setState({
            isLoading: false
          });
        });
    });
  };

  private handleFilledFieldsState = (property: string, value: any) => {
    this.setState(
      (previousState: AddCustomerState) => {
        const copyOfState = cloneDeep(previousState);
        copyOfState[property] = value;
        if (copyOfState.filledFields.find((field) => field === property) === undefined) {
          copyOfState.filledFields.push(property);
        } else {
          if (value === '') {
            copyOfState.filledFields = copyOfState.filledFields.filter((f) => f !== property);
          }
        }

        return copyOfState;
      },
      () => {
        this.performUserSearch('blur');
      }
    );
  };

  private onFieldChange = (_field: Field, value: any) => {
    this.setState({ enableButtons: value.length >= 3 });
  };

  private onSearchClick = () => {
    this.performUserSearch('click');
  };

  render() {
    const { t } = this.props;
    const isSearchOnBlur = FeatureFlags.isFlagOn('ENABLE_ENTITY_SEARCH_ON_BLUR');
    const entityFieldSets = this.props.fieldSets
      .filter((fieldset) => fieldset.group === 'entity')
      .map((fieldset) => ({
        text: fieldset.displayName,
        value: fieldset.displayName
      }));

    const activeFieldSet = this.props.fieldSets.find((fieldset) => fieldset.displayName === this.props?.dropDownValue);

    const postAttachmentOperation = activeFieldSet?.postAttachmentOperation;

    let activeFieldSetFields = undefined as any;
    if (activeFieldSet) {
      activeFieldSetFields = activeFieldSet[activeFieldSet.id];
    }

    // This is the only place in the code searchHelp is mentioned, still might be the case
    const searchInfo = activeFieldSet?.searchHelp;

    return (
      <Container>
        {this.state.results.length === 0 && (
          <Message info icon>
            <Icon name="info circle" />
            <Message.Header>
              <p>{t('ADD_CUSTOMER_DESCRIPTION')}</p>
              {searchInfo ? <p>{searchInfo}</p> : ''}
            </Message.Header>
          </Message>
        )}

        <Segment padded>
          <Form>
            {entityFieldSets.length > 1 && (
              <>
                <span>
                  {t('ENTITY_TYPE')}{' '}
                  <Dropdown
                    value={this.props?.dropDownValue as string}
                    style={{ marginLeft: '5px' }}
                    onChange={this.entityChange}
                    selection
                    placeholder="Valitse"
                    options={entityFieldSets}
                  />
                </span>
                <div className="entityTypeSelect"></div>
              </>
            )}

            <Form.Group widths="equal">
              <Info
                generalDisable={this.props.generalDisable}
                entity={activeFieldSet}
                onSave={this.fieldSave}
                onChange={this.onFieldChange}
                fields={this.props.fields}
                values={this.state.values}
                searchableFields
                taskId={this.props.task?.id}
              />
            </Form.Group>

            <Form.Group widths="equal" className="addCustomer__buttonsContainer">
              <Form.Button
                type="button"
                icon
                fluid
                positive
                labelPosition="left"
                disabled={this.props.generalDisable || !this.state.enableButtons || this.state.isLoading}
                onClick={this.createNewCustomer}
              >
                <Icon name="add user" />
                {t('ADD_CUSTOMER_CREATE_NEW_CUSTOMER')}
              </Form.Button>

              <Form.Button
                primary
                type="button"
                fluid
                icon
                disabled={
                  this.props.generalDisable || (!this.state.enableButtons && isSearchOnBlur) || this.state.isLoading
                }
                labelPosition="left"
                onClick={this.onSearchClick}
              >
                <Icon name="search" />
                {t('ADD_CUSTOMER_SEARCH_EXISTING_CUSTOMER')}
              </Form.Button>
            </Form.Group>
          </Form>

          <Header as="h4" dividing textAlign="center">
            <Icon name="search" />
            {t('SEARCH_RESULTS_LIST')}{' '}
            {!this.state.isLoading ? (this.state.results.length > 20 ? `(20+)` : `(${this.state.results.length})`) : ''}
          </Header>

          {!this.state.isLoading && this.state.isResultsReady && this.state.results.length === 0 && (
            <Message warning icon>
              <Icon name="info circle" />
              <Message.Header>
                {t('NORESULTS_CUSTOMER_TITLE_NO_CUSTOMER')}
                <p>{t('NORESULTS_CUSTOMER_DESCRIPTION')}</p>
              </Message.Header>
            </Message>
          )}

          {!this.state.isLoading && this.state.isResultsReady && this.state.results.length === 20 && (
            <Message warning icon>
              <Icon name="info circle" />
              <Message.Header>
                {t('MAXRESULTS_CUSTOMER_TITLE_NO_CUSTOMER')}
                <p>{t('MAXRESULTS_CUSTOMER_DESCRIPTION')}</p>
              </Message.Header>
            </Message>
          )}

          {!this.state.isLoading &&
            this.state.results.map((result, index) => {
              let isAttached = false;
              if (this.props.task.entities.length > 0 && result) {
                const intersectedEntities = intersectionWith(this.props.task.entities, [result], (a, b) => {
                  return a._type.toString() === b._type.toString() && a._id.toString() === b._id.toString();
                });
                if (intersectedEntities.length > 0) {
                  isAttached = true;
                }
              }

              return (
                <CustomerResult
                  isAttached={isAttached}
                  mongodb={result.mongodb || false}
                  key={index}
                  onClick={() =>
                    this.handleResultSelect({
                      ...result,
                      postAttachmentOperation
                    })
                  }
                  fields={activeFieldSetFields || this.props.fields}
                  values={result}
                />
              );
            })}

          <div>
            <Loader active={this.state.isLoading} indeterminate inline="centered">
              {t('LOADING')}
            </Loader>
          </div>
        </Segment>
      </Container>
    );
  }
}

export default withTranslation()(AddCustomer);
