import { connect } from 'react-redux';
import Main from 'src/Components/Main/Main';

import type { State } from 'src/types/initialState';

const mapStateToProps = (state: State) => {
  return {
    leftTabOpen: state.mobileReducer.leftTabOpen,
    rightTabOpen: state.mobileReducer.rightTabOpen,
    mobileMode: state.mobileReducer.mobileMode,
    userPersonalData: state.userData
  };
};

export default connect(mapStateToProps, {})(Main);
