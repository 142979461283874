import React, { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Accordion, Icon, Menu, Message } from 'semantic-ui-react';

import type { VFC } from 'react';
import type { ConnectedProps } from 'react-redux';

import TicketListAccordion from './TicketListAccordion';
import { setInfoPageListAccordion } from 'src/actions/infoPagesActionsRTK';
import { setTicketlistAccordion } from 'src/actions/ticketListTabActionsRTK';
import { updateTicket } from 'src/actions/ticketsActions';
import TicketsApi from 'src/api/TicketsApi';
import isTicketListDisable from 'src/DisableTicketList';
import { Direction, SortBy } from 'src/types/Sorting';
import { StaticTabs } from 'src/types/TicketList';
import { orderInfoPageList } from 'src/Utilities/infoPageList';
import { handleAssignOperationRejection } from 'src/Utilities/ticket';
import { doneTicketsOrdering, filterTicketList, ticketOrdering } from 'src/Utilities/ticketList';
import { startWorkingOn } from 'src/Utilities/workStatusParser';

import type { TabFilter } from 'src/types/Filter';
import type { InfoPageListTab } from 'src/types/InfoPageList';
import type { State } from 'src/types/initialState';
import type { ContentTypesFields, InfopageStatusTypes, StatusTypes, Ticket, TicketListTicket } from 'src/types/Ticket';
import type { TicketListTab } from 'src/types/TicketList';
import type { InfoPageListItem } from 'src/Utilities/infoPageList';

export interface OwnProps {
  contentType: ContentTypesFields;
}

interface Props extends TicketListReduxProps, OwnProps {}

const TicketLists: VFC<Props> = (props) => {
  const { t } = useTranslation();
  const [showError, setShowError] = useState(true);

  useEffect(() => {
    if (props.personalData?.userPreferences?.accordionsClosed) {
      props.setContentListAccordion(props.activeTabId, null);
    } else if (props.personalData?.permissions?.includes('hasLimitedView')) {
      props.setContentListAccordion(props.activeTabId, 0);
    }
  }, [props.personalData?.userPreferences?.accordionsClosed, props.personalData?.permissions]);

  /**
   * Use Redux with to store the accordion index for the different tabs
   */
  const toggleActive = (index: number | null) => {
    const accordionIndex = index === props.accordionIndex ? null : index;

    props.setContentListAccordion(props.activeTabId, accordionIndex);
  };
  const getHotKeyHandlerOptions = (key: number): Parameters<typeof useHotkeys> => [
    `alt+${key}`,
    (event) => {
      event.preventDefault();
      toggleActive(key - 1);
    },
    undefined,
    [toggleActive]
  ];

  useHotkeys(...getHotKeyHandlerOptions(1));
  useHotkeys(...getHotKeyHandlerOptions(2));
  useHotkeys(...getHotKeyHandlerOptions(3));

  const getTicketList = (
    index: number,
    title: string,
    status: StatusTypes,
    orderBy: SortBy | null = null,
    mode: Direction = Direction.DESC
  ) => {
    const { categories, personalData, usersList } = props;
    const contents = (props.contents?.filter((t) => t.status === status) ?? []) as Ticket[];
    const orderedContents =
      status === 'done'
        ? (doneTicketsOrdering(contents) as Ticket[])
        : ticketOrdering<Ticket>(contents, orderBy, mode, props.personalData.userCustomTicketOrdering);

    // FIXME: find if there's a real difference between TicketListTabFilter and InfoPageTabFilter
    const filters = props.contentsListTab?.filters ?? ({} as TabFilter);

    const tickets = filterTicketList({
      categories,
      tickets: orderedContents,
      status,
      personalData,
      filters,
      usersList
    });

    return (
      <TicketListAccordion
        type="task"
        selectedTicketId={props.selectedContentId}
        active={props.accordionIndex === index}
        index={index}
        key={index}
        status={status}
        onClick={toggleActive}
        tickets={tickets}
        title={title}
        startWorkingOn={props.startWorkingOn}
      />
    );
  };

  const getInfopageList = (
    index: number,
    title: string,
    status: InfopageStatusTypes,
    orderBy: keyof TicketListTicket | null = null,
    mode: Direction = Direction.DESC
  ) => {
    const contents = (props.contents?.filter((infopage) => infopage.status === status) || []) as InfoPageListItem[];
    const infopages = filterTicketList({
      tickets: orderInfoPageList(contents, orderBy, mode),
      personalData: props.personalData,
      // FIXME: find if there's a real difference between TicketListTabFilter and InfoPageTabFilter
      filters: props.contentsListTab?.filters as any,
      categories: props.categories
    }) as Ticket[];

    return (
      <TicketListAccordion
        type="infopage"
        selectedTicketId={props.selectedContentId}
        active={props.accordionIndex === index}
        index={index}
        key={index}
        status={status}
        onClick={toggleActive}
        tickets={infopages}
        title={title}
        startWorkingOn={props.startWorkingOn}
      />
    );
  };

  const renderContentList = () => {
    const { sorting, direction, contentType } = props;
    switch (contentType) {
      case 'tickets':
        return [
          getTicketList(0, t('CASE_STATUS_TODO_PLURAL'), 'todo', sorting, direction),
          getTicketList(1, t('CASE_STATUS_DOING_PLURAL'), 'doing', sorting, direction),
          getTicketList(2, t('CASE_STATUS_DONE_PLURAL'), 'done', SortBy.touched)
        ];
      case 'infopages':
        return [
          getInfopageList(0, t('ticket_list.status.draft'), 'draft'),
          getInfopageList(1, t('ticket_list.status.in_review'), 'inReview'),
          getInfopageList(2, t('ticket_list.status.waiting_to_be_published'), 'waitingToBePublished'),
          getInfopageList(3, t('ticket_list.status.published'), 'published'),
          getInfopageList(4, t('ticket_list.status.waste'), 'waste')
        ];
      default:
        return;
    }
  };

  if (isTicketListDisable() && props.contentsListTab?.title === 'MAIN_VIEW') {
    return (
      <>
        <div className="toastSearchTarget"></div>
      </>
    );
  }

  if (props.contentsListTab?.hasError && showError) {
    return (
      <Menu vertical={true} className="sideMenu">
        <div
          style={{
            paddingLeft: '1em',
            paddingRight: '1em',
            paddingTop: '1em'
          }}
        >
          <Message icon={true} negative={true} onDismiss={() => setShowError(false)}>
            <Icon name="warning sign" />
            <Message.Content>
              <Message.Header>{t('ERROR_HAPPENED')}</Message.Header>
              <p>{t('TICKETLIST_FETCH_FAILED')}</p>
            </Message.Content>
          </Message>
        </div>
      </Menu>
    );
  }

  return (
    <React.Fragment>
      <div className="toastSearchTarget"></div>
      <Menu vertical={true} className="sideMenu">
        <Accordion className="ticketlist" exclusive={true}>
          {renderContentList()}
        </Accordion>
      </Menu>
    </React.Fragment>
  );
};

function mapStateToProps(state: State, ownProps: OwnProps) {
  let ticketsListTab: TicketListTab | undefined;
  let infoPagesListTab: InfoPageListTab | undefined;

  switch (ownProps.contentType) {
    case 'tickets':
      ticketsListTab = Object.values(state.ticketListTabs).find((ticketListTab) => ticketListTab.activeTab);
      break;
    case 'infopages':
      infoPagesListTab = [...state.infoPageListTabs.values()].find((ticketListTab) => ticketListTab.activeTab);
      break;
  }

  const selected = state.ticketTabs.find((t) => t.activeTab);
  const selectedContentId = selected?.id ?? null;

  const contentsListTab = ownProps.contentType === 'tickets' ? ticketsListTab : infoPagesListTab;
  const activeTabId = contentsListTab?.id ?? StaticTabs.MAIN_VIEW;

  const contents = ownProps.contentType === 'tickets' ? ticketsListTab?.tickets : infoPagesListTab?.infoPages;
  const accordionIndex = contentsListTab?.accordionIndex;

  return {
    // common props
    categories: state.categories,
    personalData: state.userData,
    usersList: state.usersList.usersList,
    // Should it also work for Infopages?
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    sorting: ticketsListTab?.sorting!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    direction: ticketsListTab?.direction!,
    // specific props
    activeTabId,
    contentsListTab,
    contents,
    accordionIndex,
    selectedContentId
  };
}

function mapDispatchToProps(dispatch: any, ownProps: OwnProps) {
  const setAccordion = ownProps.contentType === 'tickets' ? setTicketlistAccordion : setInfoPageListAccordion;

  return {
    setContentListAccordion: (id: string, accordionIndex: number | null) => {
      dispatch(setAccordion({ id, accordionIndex }));
    },
    startWorkingOn: (UID: string, taskId: string) => {
      TicketsApi.startWorkingOn(...startWorkingOn(UID, taskId))
        .then(() => dispatch(updateTicket(taskId, { status: 'doing' })))
        .catch(handleAssignOperationRejection);
    }
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type TicketListReduxProps = ConnectedProps<typeof connector>;

export default connector(TicketLists);
