import { t } from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';

import type { TFunction } from 'i18next';
import type { SemanticICONS } from 'semantic-ui-react';

import TopBarStatusButtons from 'src/Components/Case/TopBar/TopBarStatusButtons';
import Widgets from 'src/Components/Case/Widget/Widgets';
import ErrorBoundary from 'src/ErrorBoundary';

import type { State } from 'src/types/initialState';

const taskStatusOptions: (t: TFunction) => {
  text: string;
  icon: SemanticICONS;
  value: string;
}[] = (t) => [
  {
    text: t('CASE_STATUS_TODO'),
    icon: 'exclamation',
    value: 'todo'
  },
  {
    text: t('CASE_STATUS_DOING'),
    icon: 'clock',
    value: 'doing'
  },
  {
    text: t('CASE_STATUS_DONE'),
    icon: 'check',
    value: 'done'
  }
];

export const DesktopLayoutRight = () => {
  const activeTask = useSelector((state: State) => state.activeTicketTab);
  if (!activeTask) {
    return <div>Right side menu.</div>;
  }
  return (
    <div id="topelement">
      <div className="case-right-side">
        <TopBarStatusButtons contentType="task" statusOptions={taskStatusOptions(t)} />
        <ErrorBoundary>
          <Widgets />
        </ErrorBoundary>
      </div>
    </div>
  );
};
