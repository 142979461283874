import type { CSSProperties } from 'react';

export const searchWrapper: CSSProperties = {
  marginBottom: '0px',
  flexDirection: 'column'
};

export const searchInput: CSSProperties = { marginBottom: '7px' };

export const bottomSectionWrapper: CSSProperties = {
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
};
