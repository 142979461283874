import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { AccordionContent, AccordionTitle, Table, TableBody } from 'semantic-ui-react';

import type { FC } from 'react';
import type { ConnectedProps } from 'react-redux';

import * as styles from './SingleLineAccordionTab.style';
import SingleLineTicketItem from './SingleLineTicketItem';
import { setTicketlistAccordion } from 'src/actions/ticketListTabActionsRTK';
import { StaticTabs } from 'src/types/TicketList';
import { statusTranslationsMap } from 'src/Utilities/constants';

import type { State } from 'src/types/initialState';
import type { TicketListTicket } from 'src/types/Ticket';

interface SingleLineAccordionTabProps extends ConnectedProps<typeof connector> {
  index: number;
  status: keyof typeof statusTranslationsMap;
  tickets: TicketListTicket[];
}

const SingleLineAccordionTab: FC<SingleLineAccordionTabProps> = ({ index, status, tickets, activeIndex }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(
      setTicketlistAccordion({
        id: StaticTabs.MAIN_VIEW,
        accordionIndex: index
      })
    );
  }, [index, dispatch]);

  return (
    <>
      <AccordionTitle index={index} active={activeIndex === index} onClick={onClick}>
        {t(statusTranslationsMap[status])} ({tickets.length})
      </AccordionTitle>
      <AccordionContent active={activeIndex === index}>
        <div style={styles.tableWrapper}>
          <Table style={styles.table}>
            <TableBody>
              {tickets.map((ticket) => (
                <SingleLineTicketItem key={`single-line-item-${status}-${ticket.id}`} ticket={ticket} />
              ))}
            </TableBody>
          </Table>
        </div>
      </AccordionContent>
    </>
  );
};

const connector = connect((state: State) => {
  const activeTab = Object.values(state.ticketListTabs).find((tab) => tab.activeTab);

  return {
    activeIndex: state.ticketListTabs[activeTab?.id ?? StaticTabs.MAIN_VIEW].accordionIndex
  };
});

export default connector(SingleLineAccordionTab);
