import React from 'react';
import { Popup } from 'semantic-ui-react';

import type { FC } from 'react';

import CommentIconContent from '../CommentIconContent/CommentIconContent';

import type ChannelType from '../CommentIconContent/ChannelType';
import type { Channel } from 'src/types/Channel';
import type { CommentMetadata } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';

interface ExternalCommentPopupProps {
  disabled?: boolean;
  metaData: CommentMetadata | null;
  channelId: ChannelType;
  commentId: string;
  ticketTypes: TicketType[];
  channels: Channel[];
  title: string | null;
  children?: React.ReactNode;
}

export const ExternalCommentPopup: FC<ExternalCommentPopupProps> = ({
  channels,
  ticketTypes,
  title,
  disabled,
  metaData,
  children,
  commentId,
  channelId
}) => {
  return (
    <Popup
      content={
        <CommentIconContent
          channels={channels}
          ticketTypes={ticketTypes}
          channel={channelId}
          title={title}
          metaData={metaData}
          showSender
        />
      }
      disabled={disabled}
      key={commentId}
      trigger={children}
      className="commentContentPopup"
      position="top center"
      flowing
      hoverable
      wide
    />
  );
};

export default ExternalCommentPopup;
