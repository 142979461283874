import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';

import type { FC } from 'react';

import { setSingleLineComments } from 'src/reducers/commentsMenuReducer';

import type { State } from 'src/types/initialState';

const SingleLineCommentsToggle: FC = () => {
  const dispatch = useDispatch();
  const singleLineComments = useSelector<State, boolean>((state) => state.commentsMenu.singleLineComments);
  const toggleSingleLineComments = useCallback(
    () => dispatch(setSingleLineComments(!singleLineComments)),
    [singleLineComments]
  );

  return (
    <div>
      <Button
        size="tiny"
        icon={<Icon color={singleLineComments ? 'green' : undefined} name="columns" />}
        onClick={toggleSingleLineComments}
      />
    </div>
  );
};

export default SingleLineCommentsToggle;
