import { Buffer } from 'buffer';
import { t } from 'i18next';
import queryString from 'query-string';
import React from 'react';
import { Cookies } from 'react-cookie';
import * as ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import type { EnhancedStore } from '@reduxjs/toolkit';
import type { History } from 'history';
import type { Action, AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';

import { loginExternal, loginExternalByBackend, loginSuccess } from './actions/authActions';
import { fetchFeatureFlagsAndInfoPages } from './actions/infoPagesActions';
import { fetchTickets } from './actions/ticketsActions';
import ApiConfig from './api/ApiConfig';
import App from './App';
import CriticalError from './CriticalError';
import Internationalization from './i18n';
import { history } from './reducers/browserHistory';
import { persistor, store } from './store';
import { StaticTabs } from './types/TicketList';
import { showErrorOverlay } from './Utilities/errorOverlay';
import { apiClient } from './Utilities/httpClients';
import { applyTokenInterceptor } from './Utilities/token-interceptor';
import serviceWorkerClient from 'src/Utilities/ServiceWorker/serviceWorkerClient';

import type { State } from 'src/types/initialState';

import './index.css';

(async () => {
  const configResult = await ApiConfig.fetchConfig();

  if (configResult.data.SENTRY_ENABLED == true) {
    const Sentry = await import("@sentry/react");
    
    Sentry.init({
      dsn: configResult.data.SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
      ],
      debug: configResult.data.SENTRY_DEBUG === true, // Default to false if not set
      tracesSampleRate: configResult.data.SENTRY_TRACE_SAMPLE_RATE,
      environment: configResult.data.SENTRY_ENV,
    });
  }
})();

serviceWorkerClient(store.dispatch, history);
window.Buffer = Buffer;

const isNotSupportedBrowser =
  navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;

const renderApp = (storeObj: EnhancedStore<State>, historyObj: History) => {
  ReactModal.setAppElement('#root');
  ReactDOM.render(
    <Provider store={storeObj}>
      <PersistGate loading={null} persistor={persistor}>
        <App history={historyObj} />
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
};

const cookies = new Cookies();

(async () => {
  // Register error overlay for development mode
  if (process.env.NODE_ENV === 'development') {
    window.addEventListener('error', ({ error }) => showErrorOverlay(error));
    window.addEventListener('unhandledrejection', ({ reason }) => showErrorOverlay(reason));
  }

  try {
    const configResult = await ApiConfig.fetchConfig();
    ApiConfig.setConfig(configResult.data);
    await ApiConfig.fetchMetadata();
    await Internationalization.init();
    applyTokenInterceptor(apiClient);

    if (configResult.data.CUSTOM_TRANSLATION_URL !== undefined) {
      await Internationalization.fetchTranslations(configResult.data.CUSTOM_TRANSLATION_URL);
    }

    if (isNotSupportedBrowser) {
      setTimeout(() => {
        ReactDOM.render(
          <h4 className="loginNoIEPrompt">{t<string>('APP_UNSUPPORTED_BROWSER')}</h4>,
          document.getElementById('root')
        );
      }, 1000);
    } else if (
      store.getState().auth.external.isBackendToken ||
      store.getState().auth.external.token ||
      store.getState().auth.external.username
    ) {
      // make login request to /loginExternal route
      if (store.getState().auth.external.isBackendToken) {
        history.replace(history.location.pathname);
        store.dispatch(
          loginExternalByBackend(
            store.getState().auth.external.token,
            store.getState().auth.external.username
          ) as unknown as Action
        );
        renderApp(store, history);
      } else {
        store.dispatch(
          loginExternal(
            store.getState().auth.external.token,
            store.getState().auth.external.username
          ) as unknown as Action
        );
        renderApp(store, history);
      }
    } else if (
      store.getState().auth.loggedIn ||
      cookies.get('loggedIn') ||
      localStorage.getItem('loggedIn') === 'true' ||
      history.location.pathname === '/redirected-from-sso'
    ) {
      const parsedSearch = queryString.parse(window.location.search);

      if (parsedSearch.accessTokenExpiration) {
        localStorage.setItem('accessTokenExpiration', String(parsedSearch.accessTokenExpiration));
        history.replace('/');
      }

      localStorage.setItem('loggedIn', 'true');
      // is logged in, get users, tags and personaldata
      apiClient.defaults.withCredentials = true;
      store.dispatch(loginSuccess());
      await Promise.all([
        (store.dispatch as ThunkDispatch<Promise<any>, any, AnyAction>)(fetchTickets('', StaticTabs.MAIN_VIEW)),
        (store.dispatch as ThunkDispatch<Promise<any>, any, AnyAction>)(fetchFeatureFlagsAndInfoPages())
      ]);

      renderApp(store, history);
    } else {
      renderApp(store, history);
    }
  } catch (error) {
    console.error('Failed to fetch config.json-file, cannot initialize app!', error);
    ReactDOM.render(<CriticalError />, document.getElementById('root'));
  }
})();
