import iziToast from 'izitoast';
import * as React from 'react';
import { connect } from 'react-redux';

import ApiConfig from './api/ApiConfig';

import type { State } from 'src/types/initialState';
import type { PersonalData, User } from 'src/types/User';

interface RedirectRoleProps {
  users: User[];
  personalData: PersonalData;
}

const mapStateToProps = (state: State) => ({
  users: state.usersList.usersList,
  personalData: state.userData
});

class RedirectRole extends React.Component<RedirectRoleProps> {
  componentDidMount() {
    if (ApiConfig.getConfig().REDIRECT_ROLE6 !== undefined) {
      const userId = this.props.personalData.UID;
      const user = this.props.users.find((x: User) => x.UID === userId);
      if (user?.role?.id === 'ROL6') {
        window.location.href = ApiConfig.getConfig().REDIRECT_ROLE6 as string;
        iziToast.info({
          title: 'Redirecting',
          message: '...'
        });
      }
    }
  }
  render() {
    return null;
  }
}

export default connect(mapStateToProps)(RedirectRole);
