import { isEmpty } from 'lodash-es';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Card, Icon, Label, Loader } from 'semantic-ui-react';

import type { WithTranslation } from 'react-i18next';
import type { ConnectedProps } from 'react-redux';

import TicketsApi from 'src/api/TicketsApi';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { apiClient } from 'src/Utilities/httpClients';
import { sanitizeHTML } from 'src/Utilities/sanitize';

import type { State } from 'src/types/initialState';
import type { Comment, TicketDetailsData } from 'src/types/Ticket';
import type { FieldSet } from 'src/types/TicketType';
import type { User } from 'src/types/User';

interface TicketAdditionalDetailsState extends TicketDetailsData {
  isLoading: boolean;
  content: string;
}

const connector = connect((state: State) => ({
  users: state.usersList.usersList
}));

interface TicketAdditionalDetailsProps extends ConnectedProps<typeof connector>, WithTranslation {
  fields: FieldSet[] | undefined;
  id: string;
  title: string;
  ticketType: string | undefined;
  created: number;
  touched: number;
  dueDate: number | undefined;
  originalMsg: boolean;
  data?: TicketDetailsData;
  onLoaded?: () => void;
}

class TicketAdditionalDetails extends React.Component<TicketAdditionalDetailsProps, TicketAdditionalDetailsState> {
  constructor(props: TicketAdditionalDetailsProps) {
    super(props);

    this.state = {
      isLoading: !props.data,
      comments: props.data?.comments ?? [],
      entities: props.data?.entities ?? [],
      editedByUser: props.data?.editedByUser ?? '',
      content: ''
    };
  }

  componentDidMount() {
    if (!this.props.data) {
      this.fetchDetails(this.props.id);
    }
  }

  fetchDetails = (id: string): void => {
    const type = this.props.id.substring(0, 3);
    TicketsApi.getTicket(id, { type })
      .then((response) => {
        response.entities.forEach((entity) => {
          this.fetchEntity(entity._id, entity._type, response.taskType);
        });
        // FIXME: it's a result of mess with types Ticket, TicketListTicket, Comment etc.
        // should be equalized to inherit same base type
        this.setState({
          content: response.content,
          comments: response.comments,
          entities: response.entities,
          isLoading: false
        });
        this.props.onLoaded?.();
      })
      .catch((error) => console.log(error));
  };

  private fetchEntity = async (id: any, type: string, contentType?: string) => {
    try {
      const response = await apiClient.get('/entities/' + id + '?entityType=' + type + '&ticketType=' + contentType, {
        timeout: 15000
      });
      const state = { ...this.state };
      state.entities = [...state.entities, response.data];
      this.setState(state);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  private getLastComment = () => {
    const { t } = this.props;
    const comments = this.state.comments.filter((comment: Comment) => comment.type === 'normal');

    if (isEmpty(comments)) {
      return <div style={{ color: 'grey' }}>{t('ADDITIONAL_DETAILS_NO_COMMENTS')}</div>;
    }

    const commentUser = this.props.users.find((usr) => usr.UID === comments[comments.length - 1].createdByUser);

    return (
      <div>
        <div
          className="ticketAdditionalDetailsCardLastComment"
          dangerouslySetInnerHTML={{
            __html: sanitizeHTML(comments[comments.length - 1].content || '')
          }}
        />
        <p style={{ color: 'grey', float: 'right', marginRight: '5px' }}>
          <em>
            {getPrettyDate(comments[comments.length - 1].created, { format: DATE_TIME_FORMAT })}-{' '}
            {commentUser?.profile.firstName} {commentUser?.profile.lastName}
          </em>
        </p>
      </div>
    );
  };

  private getEmailFieldValue = (entityObj: any, emailField: any) => {
    if (!entityObj || !entityObj[emailField] || !entityObj[emailField.value]) {
      return null;
    }

    const key = typeof entityObj[emailField.value] === 'object' ? emailField.value.address : emailField.value;

    return (
      <div>
        <Icon name="envelope" />
        {entityObj[key]}
      </div>
    );
  };

  private getPhoneFieldValue = (entityObj: any, phoneField: any) => {
    if (!entityObj || !phoneField || !entityObj[phoneField] || !entityObj[phoneField.value]) {
      return null;
    }

    return (
      <div>
        <Icon name="phone" />
        {entityObj[phoneField.value]}
      </div>
    );
  };

  private mapTicketEntities = () => {
    const entities = this.state.entities;

    if (isEmpty(entities)) {
      return null;
    }

    const sortedEntities = entities.reduce((previousValue, currentValue) => {
      const previousArray = previousValue[currentValue._type] !== undefined ? previousValue[currentValue._type] : [];
      previousValue[currentValue._type] = [...previousArray, currentValue];
      return previousValue;
    }, {});

    return Object.keys(sortedEntities).map((entityKey: string) => {
      const fields: FieldSet | undefined =
        this.props.fields?.find(
          (fieldSet: FieldSet) => Array.isArray(fieldSet.entityTypes) && fieldSet.entityTypes.indexOf(entityKey) !== -1
        ) ?? this.props.fields?.find((x: FieldSet) => x.id === 'customerInfo');

      return (
        <div className="customer-container-color" key={entityKey}>
          <div style={{ marginLeft: '9px' }}>
            <b>
              {fields?.displayName} ({sortedEntities[entityKey].length})
            </b>{' '}
          </div>
          {sortedEntities[entityKey].map((obj: any) => {
            const matchTicketType = obj._type;

            const correctFieldSet = this.props.fields?.find((field: FieldSet) =>
              Array.isArray(field.entityTypes)
                ? field.entityTypes.indexOf(matchTicketType) !== -1
                : field.id === 'customerInfo'
            );

            const entityObj = this.state.entities.find((entity) => entity._id === obj._id);

            const name1Field = correctFieldSet?.customerInfo
              ? correctFieldSet?.customerInfo.find((field) => field.displayField === 'name1')
              : undefined;
            const name2Field = correctFieldSet?.customerInfo
              ? correctFieldSet?.customerInfo.find((field) => field.displayField === 'name2')
              : undefined;
            const emailField = correctFieldSet?.customerInfo
              ? correctFieldSet?.customerInfo.find((field) => field.displayField === 'emailField')
              : undefined;
            const phoneField = correctFieldSet?.customerInfo
              ? correctFieldSet?.customerInfo.find((field) => field.displayField === 'phoneField')
              : undefined;

            return (
              <div style={{ marginLeft: '10px', padding: '5px' }} key={obj._id}>
                <b>
                  {entityObj?.[name1Field?.value as string]} {entityObj?.[name2Field?.value as string]}
                </b>
                {this.getEmailFieldValue(entityObj, emailField)}
                {this.getPhoneFieldValue(entityObj, phoneField)}
              </div>
            );
          })}
        </div>
      );
    });
  };

  private getLastEditedUser = () => {
    const userObj = this.props.users.find((user: User) => user.UID === this.state.editedByUser);

    return userObj?.profile?.firstName + ' ' + userObj?.profile?.lastName;
  };

  render() {
    const { title, id, created, touched, dueDate, t } = this.props;

    if (this.state.isLoading) {
      return <Loader active inline content={t('LOADING')} />;
    }

    if (!this.props.originalMsg) {
      return null;
    }

    return (
      <>
        {this.props.originalMsg && (
          <Card fluid className="ticketAdditionalDetailsCard">
            <Card.Content>
              <Card.Header>{title}</Card.Header>
              <Card.Meta style={{ marginTop: '8px', paddingBottom: '2px' }}>
                <div style={{ paddingBottom: '10px' }}>{id}</div>

                <Label>
                  <Icon name="plus" />
                  <span style={{ marginLeft: '5px' }}>{getPrettyDate(created, { format: DATE_TIME_FORMAT })}</span>
                </Label>
                <Label>
                  <Icon name="pencil" />
                  <span style={{ marginLeft: '5px' }}>{getPrettyDate(touched, { format: DATE_TIME_FORMAT })}</span>
                </Label>
                {dueDate && (
                  <Label>
                    <Icon name="time" />
                    <span style={{ marginLeft: '5px' }}>{getPrettyDate(dueDate, { format: DATE_TIME_FORMAT })}</span>
                  </Label>
                )}
              </Card.Meta>
            </Card.Content>
            <Card.Description style={{ marginLeft: '10px' }}>
              {!isEmpty(this.state.editedByUser) && (
                <div style={{ marginBottom: '8px' }}>
                  <Icon name="edit" /> {this.getLastEditedUser()}
                </div>
              )}
              {this.getLastComment()}
            </Card.Description>
            <Card.Description>{this.mapTicketEntities()}</Card.Description>
          </Card>
        )}
      </>
    );
  }
}

export default withTranslation()(connector(TicketAdditionalDetails));
