import React, { useState } from "react";
import { Label, List, Popup, Search } from "semantic-ui-react"

const mockCommandPrompts = [
    { value: "1", text: "Open a ticket", shortcut: "⌘,A" },
    { value: "2", text: "Create a new ticket using template...", shortcut: "⌘,B" },
    { value: "3", text: "Go to settings ", shortcut: "⌘,C" },
    { value: "4", text: "Minimise widgets", shortcut: "⌘,D" },
    { value: "5", text: "Minimise ticketlist", shortcut: "⌘,E" },
    { value: "6", text: "Sort discussion by date", shortcut: "⌘,F" },
];

const mockContentPrompts = [
    { value: "1", text: "New bug in production", shortcut: "⌘,1" },
    { value: "2", text: "New bug in production #2", shortcut: "⌘,2" },
    { value: "3", text: "Customer complaint", shortcut: "⌘,3" },
    { value: "4", text: "Create a new environment", shortcut: "⌘,4" },
    { value: "5", text: "New feature: auto-complete for tickets", shortcut: "⌘,5" },
    { value: "6", text: "New feature: kanban view", shortcut: "⌘,6" },
];

const ShortCut = (props: { keypath: string }) => {
    return (
        <span style={{float: 'right'}}>
            {props.keypath.split(',').map(k => <Label className="command-palette-list-label-border" content={k} />)}
        </span>
    );
}

export const CommandPalette = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [input, setInput] = useState("");

    let options = input.includes('>') ? mockCommandPrompts : mockContentPrompts;
    options = options.filter(option => option.text.toLowerCase().replaceAll(' ', '').includes(input.toLowerCase().replaceAll('>', '').replaceAll(' ', '')));

    return (
        <Popup
            trigger={(<Search
                placeholder='Search in Desk'
                onSearchChange={(_, data) => setInput(data.value || "")}
                onFocus={() => setIsOpen(true)}
                onBlur={() => setIsOpen(false)}
                fluid
            />)}
            open={isOpen}
            position="bottom center"
            wide
            content={(
                <div style={{width: '500px'}}>
                    <List divided relaxed>
                        {options.map(prompt => (
                            <List.Item className="command-palette-list">
                                <List.Content>
                                    {!input.includes('>') && (<Label content={`TSK` + Math.floor(Math.random() * 100000)} />)}
                                    <span style={{marginLeft: '10px', marginRight: '10px'}}>
                                        {prompt.text}
                                    </span>
                                    <ShortCut keypath={prompt.shortcut} />
                                </List.Content>
                            </List.Item>
                        ))}
                    </List>
                </div>
            )}
        />
    )
}