import type { AxiosResponse } from 'axios';

import { apiClient, publicHttpClient } from 'src/Utilities/httpClients';

interface ApiConfigurationParameters {
  API_URL: string;
  REAL_TIME_SERVICE_ADDRESS: string;
  API_MAIN_CONTENT_TAGS: string[];
  AD_ENABLED: boolean;
  DEFAULT_LANGUAGE: string;
  LIKEIT_AUTH: boolean;
  SURVEY_API_URL: string;
  REDIRECT_ROLE6?: string;
  GOOGLE_API_KEY: string;
  SW_DEBUG?: boolean;
  SURVEY_COMPONENTS_HOST?: string;
  OVERRIDE_FAVICON?: string;
  OVERRIDE_TITLE?: string;
  SENTRY_ENABLED?: boolean;
  SENTRY_DSN?: string;
  SENTRY_DEBUG?: boolean;
  SENTRY_ENV?: string;
  SENTRY_TRACE_SAMPLE_RATE?: Number;
}

interface MetaDataResponse {
  version: string;
}

export default class ApiConfig {
  static version = '';
  static isVersionCheckPostponed = false;

  static config: ApiConfigurationParameters = {
    API_URL: '',
    REAL_TIME_SERVICE_ADDRESS: '',
    API_MAIN_CONTENT_TAGS: [],
    DEFAULT_LANGUAGE: 'en',
    AD_ENABLED: false,
    LIKEIT_AUTH: false,
    GOOGLE_API_KEY: '',
    SURVEY_API_URL: '',
    SW_DEBUG: false,
    SURVEY_COMPONENTS_HOST: '',
    SENTRY_ENABLED: false,
    SENTRY_DSN: '',
    SENTRY_DEBUG: false,
    SENTRY_ENV: 'dev',
    SENTRY_TRACE_SAMPLE_RATE: 0.1,
  };

  static get apiUrl() {
    return this.getConfig().API_URL;
  }

  static fetchConfig() {
    return publicHttpClient.get('/config/config.json');
  }

  static async fetchMetadata() {
    return publicHttpClient.get('/metadata/metadata.json').then((result: AxiosResponse<MetaDataResponse>) => {
      this.version = result.data.version;
      this.isVersionCheckPostponed = false;
    });
  }

  static setMetaDataVersionPostpone(value: boolean) {
    this.isVersionCheckPostponed = value;
  }

  static isRunningCurrentVersion() {
    if (import.meta.env.VITE_CONTAINER_IMAGE === 'development' || this.version === 'development') {
      return true;
    } else {
      return import.meta.env.VITE_CONTAINER_IMAGE === this.version;
    }
  }

  static setConfig(incomingConfig: ApiConfigurationParameters) {
    this.config = {
      ...this.config,
      ...incomingConfig
    };
    apiClient.defaults.baseURL = incomingConfig.API_URL;
  }

  static getConfig() {
    return this.config;
  }
}
