import type { ResponseTemplate } from '@eeedo/types';

import { apiClient } from 'src/Utilities/httpClients';

export default class ResponseTemplatesApi {
  static getTemplateTypeParams = (type: string, entityId: string, entityType: string, ticketId: string): any => {
    return apiClient
      .get('/responseTemplates/type/' + type + '/' + entityId + '/' + entityType + '/' + ticketId)
      .then((response) => response.data);
  };
  static getTemplate = (id: string): Promise<ResponseTemplate> => {
    return apiClient.get('responseTemplates/' + id).then((response) => response.data);
  };
  static createTemplate = (template: ResponseTemplate): Promise<ResponseTemplate> => {
    return apiClient.post('/responseTemplates', template).then((response) => response.data);
  };
  static updateTemplate = (template: ResponseTemplate): Promise<ResponseTemplate> => {
    return apiClient.put('/responseTemplates/' + template._id, template).then((response) => response.data);
  };
  static deleteTemplate = (id: string): Promise<any> => {
    return apiClient
      .delete('/responseTemplates/' + id, {
        data: {
          _id: id
        }
      })
      .then((response) => response.data);
  };
}
