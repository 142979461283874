import React, { useCallback, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';
import type { ButtonProps } from 'semantic-ui-react';
import { Button } from 'semantic-ui-react';

import type { CSSProperties, FC } from 'react';

import ChannelType from '../CommentIconContent/ChannelType';
import { DEFAULT_HEIGHT } from '../generic/FloatingWindow/constants';
import { addFloatingWindow } from 'src/reducers/floatingWindowsReducer';
import { FloatingWindowPhoneIntegrations, FloatingWindowTypes } from 'src/types/FloatingWindow';
import { hasPhoneIntegrations } from 'src/Utilities/phoneIntegrations';

import type { State } from 'src/types/initialState';

const userHasPermissionsCheck = (userData: State['userData']) => {
  return userData.permissions.includes('allowPhoneIntegrations') && userData.channels.includes(ChannelType.Phone);
};

const phoneButtonStyles: CSSProperties = {
  borderRadius: '0.75rem .75rem 0 0',
  paddingLeft: '45px',
  paddingRight: '45px'
};

const phoneButtonWrapperStyles: CSSProperties = {
  position: 'fixed',
  bottom: 0,
  left: 0
};

const PhoneButtonsFloating: FC = () => {
  const dispatch = useDispatch();

  const openPhoneWindow = useCallback(
    (integration: FloatingWindowPhoneIntegrations, title: string) => (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      dispatch(
        addFloatingWindow({
          title,
          id: FloatingWindowTypes.Phone,
          type: FloatingWindowTypes.Phone,
          width: 700,
          // FIXME
          data: { integration } as any,
          // 10 is a margin
          x: 10,
          y: window.innerHeight - DEFAULT_HEIGHT - 25
        })
      );
    },
    [dispatch]
  );

  const userHasPermissions = useSelector((state: State) => userHasPermissionsCheck(state.userData));

  const { hasElisaOCIntegration, hasElisaRingIntegration, hasMitelIntegration, hasEnreachVoiceIntegration } =
    hasPhoneIntegrations();

  const phoneIntegrations = useMemo(
    () => [
      {
        render: (buttonContent: string | null, extraButtonProps: Partial<ButtonProps>) => (
          <Button
            color="blue"
            style={buttonContent ? phoneButtonStyles : null}
            onClick={openPhoneWindow(FloatingWindowPhoneIntegrations.oc, 'Elisa OC')}
            {...(extraButtonProps ?? {})}
          >
            {buttonContent}
          </Button>
        ),
        integration: FloatingWindowPhoneIntegrations.oc,
        title: 'Elisa OC',
        enabled: hasElisaOCIntegration
      },
      {
        render: (buttonContent: string | null, extraButtonProps: Partial<ButtonProps>) => (
          <Button
            color="blue"
            style={buttonContent ? phoneButtonStyles : null}
            onClick={openPhoneWindow(FloatingWindowPhoneIntegrations.ring, 'Elisa Ring')}
            {...(extraButtonProps ?? {})}
          >
            {buttonContent}
          </Button>
        ),
        integration: FloatingWindowPhoneIntegrations.ring,
        title: 'Elisa Ring',
        enabled: hasElisaRingIntegration
      },
      {
        render: (buttonContent: string | null, extraButtonProps: Partial<ButtonProps>) => (
          <Button
            color="blue"
            style={buttonContent ? phoneButtonStyles : null}
            onClick={openPhoneWindow(FloatingWindowPhoneIntegrations.mittel, 'Mittel')}
            {...(extraButtonProps ?? {})}
          >
            {buttonContent}
          </Button>
        ),
        integration: FloatingWindowPhoneIntegrations.mittel,
        title: 'Mittel',
        enabled: hasMitelIntegration
      },
      {
        render: (buttonContent: string | null, extraButtonProps: Partial<ButtonProps>) => (
          <Button
            color="blue"
            style={buttonContent ? phoneButtonStyles : null}
            onClick={openPhoneWindow(FloatingWindowPhoneIntegrations.enreach, 'Voice')}
            {...(extraButtonProps ?? {})}
          >
            {buttonContent}
          </Button>
        ),

        integration: FloatingWindowPhoneIntegrations.enreach,
        title: 'Voice',
        enabled: hasEnreachVoiceIntegration
      }
    ],
    [openPhoneWindow, hasElisaRingIntegration, hasElisaOCIntegration, hasMitelIntegration, hasEnreachVoiceIntegration]
  );

  const integrationDataPerShortcutNumber = {
    4: phoneIntegrations[0],
    5: phoneIntegrations[1],
    6: phoneIntegrations[2],
    7: phoneIntegrations[3]
  };

  const getHotKeyHandlerOptions = (key: number): Parameters<typeof useHotkeys> => [
    `alt+${key}`,
    (event: KeyboardEvent) => {
      event.preventDefault();
      const data = integrationDataPerShortcutNumber[key];
      if (!data.enabled) {
        return;
      }
      dispatch(
        addFloatingWindow({
          title: data.title,
          id: FloatingWindowTypes.Phone,
          type: FloatingWindowTypes.Phone,
          width: 700,
          // FIXME
          data: { integration: data.integration } as any,
          // 10 is a margin
          x: 10,
          y: window.innerHeight - DEFAULT_HEIGHT - 25,
          fold: false
        })
      );
    },
    undefined,
    []
  ];

  useHotkeys(...getHotKeyHandlerOptions(4));
  useHotkeys(...getHotKeyHandlerOptions(5));
  useHotkeys(...getHotKeyHandlerOptions(6));
  useHotkeys(...getHotKeyHandlerOptions(7));

  if (!userHasPermissions) {
    return null;
  }

  return (
    <div style={phoneButtonWrapperStyles}>
      {phoneIntegrations
        .filter((integration) => integration.enabled)
        .map((integration, _, filteredIntegrations) => {
          let buttonContent: string | null = integration.title;
          let extraButtonProps = {};
          if (filteredIntegrations.length === 1) {
            buttonContent = null;
            extraButtonProps = {
              icon: 'phone',
              circular: true,
              size: 'big',
              style: { marginBottom: '10px', marginLeft: '10px' }
            };
          }
          return integration.render(buttonContent, extraButtonProps);
        })}
    </div>
  );
};

export default PhoneButtonsFloating;
