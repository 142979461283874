import debounce from 'lodash-es/debounce';
import React, { useEffect, useState } from 'react';
import { Divider, Icon, Input, Popup } from 'semantic-ui-react';

import type { SemanticICONS } from 'semantic-ui-react';

import { useFocusMode } from 'src/Components/FocusMode/FocusModeContext';

const _Menu = () => {
  const filters: { id: number; icon: SemanticICONS; name: string; counts: number[] }[] = [
    { id: 1, icon: 'phone square', name: 'Support tickets', counts: [71, 4, 171] },
    { id: 2, icon: 'user', name: 'My tickets', counts: [31, 1, 17] },
    { id: 3, icon: 'money bill alternate', name: 'Invoices', counts: [20, 0, 100] },
    { id: 4, icon: 'bug', name: 'Bug tickets', counts: [9, 3, 148] }
  ];

  const [activeIds, setActiveIds] = useState<number[]>([]);

  const [search, setSearch] = useState<string>('');

  const debounceHandleSearch = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, 250);

  const handleToggle = (id: number) => {
    const indexOfId = activeIds.indexOf(id);
    if (indexOfId === -1) {
      setActiveIds([...activeIds, id]);
    } else {
      activeIds.splice(indexOfId, 1);
      setActiveIds([...activeIds]);
    }
  };

  const matchingFilters = filters.filter((x) => x.name.toLowerCase().includes(search));

  return (
    <div className="list-of-filters-main">
      <h3><Icon name="filter" />Filters</h3>
      <Input fluid icon='search' placeholder='Search...' onChange={debounceHandleSearch} />
      <Divider />
      <div className="list-of-filters-list">
        {matchingFilters.length === 0 && <span>No matching filters</span>}
        {matchingFilters.map((filter) => (
          <Popup
            trigger={
              <div
                className={`list-of-filters-list-item ${activeIds.includes(filter.id) && 'active'}`}
                onClick={() => {
                  handleToggle(filter.id);
                }}
              >
                <div className="list-of-filters-list-item-name">
                  <Icon name={filter.icon} style={{ marginRight: '5px' }} />
                  {filter.name}
                </div>
                <div className="list-of-filters-list-item-count">{filter.counts.reduce((x, y) => x + y, 0)}</div>
              </div>
            }
            content={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <Icon name={filter.icon} />
                  {filter.name}
                </div>
                <Divider />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}>
                    <div className="list-of-filters-list-item-name">Todo</div>
                    <div className="list-of-filters-list-item-count">{filter.counts[0]}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}>
                    <div className="list-of-filters-list-item-name">Doing</div>
                    <div className="list-of-filters-list-item-count">{filter.counts[1]}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}>
                    <div className="list-of-filters-list-item-name">Done</div>
                    <div className="list-of-filters-list-item-count">{filter.counts[2]}</div>
                  </div>
                </div>
              </div>
            }
            position="right center"
          />
        ))}
      </div>
    </div>
  );
};

export const ListOfPresetFilters = () => {
  const [isVisible, setIsVisible] = useState(true);
  const { isFocusMode } = useFocusMode();

  const icon: 'arrow left' | 'arrow right' = `arrow ${isVisible ? 'left' : 'right'}`;

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    setIsVisible(!isFocusMode);
  }, [isFocusMode]);

  return (
    <div className={`layout-desktop-filters ${isVisible && 'active'}`}>
      <div className="layout-desktop-filters-controls">
        <Icon name={icon} onClick={toggleVisibility} />
      </div>
      {isVisible && <_Menu />}
      {!isVisible && <div style={{ height: '20px', width: '30px' }}></div>}
    </div>
  );
};
