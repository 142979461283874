import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Header, Icon, List, Loader, Segment } from 'semantic-ui-react';

import type { FC } from 'react';

import type { InitialLoadProps } from 'src/containers/InitialLoadContainer';

export type initialRequestsTypes =
  | 'users'
  | 'ticketTypes'
  | 'responseTemplates'
  | 'titleTemplates'
  | 'channelTypes'
  | 'linkLists'
  | 'personalData'
  | 'tags'
  | 'categories'
  | 'envSettings'
  | 'featureFlags'
  | 'roles'
  | 'caseImportTemplates'
  | 'priorities'
  | 'PhoneConfigurations'
  | 'chatStatuses'
  | 'PostIntegrationConfiguration'
  | 'autoSuggestions'
  | 'webhooks';

export interface InitialRequestState {
  type: initialRequestsTypes;
  isLoading: boolean;
  isCompleted: boolean;
  error: null | Error;
}

const InitialLoad: FC<InitialLoadProps> = ({
  children,
  initialRequests,

  logout,
  refreshToken,
  fetchEnvSettings,
  fetchFeatureFlags,
  fetchUsers,
  fetchTicketTypes,
  fetchAutoSuggestions,
  fetchChannelTypes,
  fetchResponseTemplates,
  fetchTitleTemplates,
  fetchLinkLists,
  fetchTags,
  fetchCategories,
  fetchChats,
  fetchRoles,
  fetchTicketPriorities,
  fetchPersonalData,
  fetchCaseImportTemplates,
  fetchPriorities,
  fetchPhoneConfigurations,
  setInitialRequestToLoading,
  fetchWebhooks
}) => {
  const { t } = useTranslation();
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    const accessTokenExpiration = localStorage.getItem('accessTokenExpiration');
    if (accessTokenExpiration) {
      refreshToken(true);
    }

    // Dispatch all requests here!
    fetchEnvSettings();
    fetchFeatureFlags();
    fetchUsers();
    fetchTicketTypes();
    fetchAutoSuggestions();
    fetchChannelTypes();
    fetchResponseTemplates();
    fetchTitleTemplates();
    fetchLinkLists();
    fetchTags();
    fetchCategories();
    fetchChats();
    fetchRoles();
    fetchTicketPriorities();
    fetchPersonalData();
    fetchCaseImportTemplates();
    fetchPriorities();
    fetchPhoneConfigurations();
    fetchWebhooks();
  }, []);

  const getIcon = (initialRequest: InitialRequestState) => {
    if (initialRequest.isCompleted) {
      return <List.Icon name="check" color="green" size="big" verticalAlign="middle" />;
    } else if (initialRequest.isLoading) {
      return (
        <List.Icon verticalAlign="middle">
          <Loader inline={true} active={true} indeterminate={true} />
        </List.Icon>
      );
    } else if (initialRequest.error !== null) {
      return <List.Icon name="warning circle" size="big" color="red" verticalAlign="middle" />;
    } else {
      return <List.Icon name="question" size="big" color="yellow" verticalAlign="middle" />;
    }
  };

  const fetchAgain = (reqState: InitialRequestState) => {
    setInitialRequestToLoading(reqState.type);
    switch (reqState.type) {
      case 'users':
        fetchUsers();
        break;
      case 'ticketTypes':
        fetchTicketTypes();
        break;
      case 'channelTypes':
        fetchChannelTypes();
        break;
      case 'responseTemplates':
        fetchResponseTemplates();
        break;
      case 'titleTemplates':
        fetchTitleTemplates();
        break;
      case 'linkLists':
        fetchLinkLists();
        break;
      case 'tags':
        fetchTags();
        break;
      case 'categories':
        fetchCategories();
        break;
      case 'personalData':
        fetchPersonalData();
        break;
      case 'envSettings':
        fetchEnvSettings();
        break;
      case 'featureFlags':
        fetchFeatureFlags();
        break;
      case 'roles':
        fetchRoles();
        break;
      case 'caseImportTemplates':
        fetchCaseImportTemplates();
        break;
      case 'PhoneConfigurations':
        fetchPhoneConfigurations();
        break;
      case 'chatStatuses':
        fetchChats();
        break;
      case 'autoSuggestions':
        fetchAutoSuggestions();
        break;
      case 'webhooks':
        fetchWebhooks();
        break;
      default:
        console.error('Could not fetch again this type!');
    }
  };

  const getStatus = (initialRequest: InitialRequestState) => {
    if (initialRequest.isCompleted) {
      return t('INIT_LOAD_REQUEST_STATE_COMPLETED');
    } else if (initialRequest.isLoading) {
      return t('INIT_LOAD_REQUEST_STATE_LOADING');
    } else if (initialRequest.error !== null) {
      return (
        <a onClick={() => fetchAgain(initialRequest)}>
          <Icon name="redo" size="small" />
          {t('INIT_LOAD_REQUEST_STATE_ERROR')}
        </a>
      );
    } else {
      return t('INIT_LOAD_REQUEST_STATE_UNKNOWN');
    }
  };

  const sortStatuses = (a: InitialRequestState, b: InitialRequestState) => (a.type > b.type ? 1 : -1);

  const completedRequests = initialRequests.filter((reqStatus: InitialRequestState) => reqStatus.isCompleted);
  const hasErrors = initialRequests.filter((reqStatus: InitialRequestState) => reqStatus.error !== null).length > 0;
  const allHasCompleted = completedRequests.length === initialRequests.length;

  if (showChildren === true || allHasCompleted) {
    return children;
  }

  return (
    <Segment
      style={{
        width: '75%',
        position: 'absolute',
        top: '10%',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#14375a'
      }}
      className="intialLoad"
    >
      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column>
            <div className="logo" style={{ width: '360px', height: '80px' }} />
          </Grid.Column>
          <Grid.Column verticalAlign="middle">
            <Header align="center" as="h2" style={{ color: 'white' }}>
              {t('EEEDO_CUSTOMER_SERVICE_MODULE')}
              <Header.Subheader style={{ color: 'white' }}>
                {import.meta.env.VITE_CONTAINER_IMAGE}
                <br />
                {hasErrors && t('INITIAL_LOAD_ERRORS')}
                {!hasErrors && t('INITIAL_LOAD_LOADING_FILES')}
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <List relaxed={true} style={{ backgroundColor: 'white', padding: '20px' }}>
        {initialRequests.sort(sortStatuses).map((initialRequest: InitialRequestState) => (
          <List.Item key={initialRequest.type}>
            {getIcon(initialRequest)}
            <List.Content>
              <List.Header>{t('INIT_LOAD_TITLE_' + initialRequest.type)}</List.Header>
              <List.Description>{getStatus(initialRequest)}</List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
      {hasErrors && (
        <div
          style={{
            padding: '20px',
            backgroundColor: 'white',
            width: '100%',
            color: 'red'
          }}
        >
          <p>
            <Icon name="warning sign" />
            {t('INITIAL_LOAD_DESCRIPTION_RECOMMEND_LOGOUT')}
          </p>
        </div>
      )}
      <div
        className="initialLoadBtnContainer"
        style={{
          padding: '20px',
          backgroundColor: 'white',
          width: '100%'
        }}
      >
        <Button negative={true} onClick={() => setShowChildren(true)} labelPosition="left" icon={true}>
          <Icon name="warning sign" />
          {t('INIT_LOAD_CONTINUE_ANYWAY')}
        </Button>
        <Button
          className="initialLoadLogOutBtn"
          primary={true}
          onClick={() => logout({ type: 'manual' })}
          labelPosition="left"
          icon={true}
          floated="right"
        >
          <Icon name="log out" />
          {t('MAIN_TOPBAR_LOGOUT')}
        </Button>
      </div>
    </Segment>
  );
};

export default React.memo(InitialLoad);
