import React, { ReactNode, useState } from 'react';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Divider, Icon, MenuItem, SemanticICONS } from 'semantic-ui-react';
import { ListOfPresetFilters } from './ListOfPresetFilters';

interface MenuItem { icon: SemanticICONS, text: string, href?: string };

const menuItems: MenuItem[] = [
    {
      icon: "home",
      text: "Main view",
      href: "/"
    },
    {
      icon: "book",
      text: "Knowledge base",
      href: "/infopage"
    },
    {
      icon: "external square alternate",
      text: "Export data"
    },
    {
      icon: "chart bar",
      text: "Reporting",
      href: "/reporting"
    },
    {
      icon: "table",
      text: "Aggregate view",
      href: "/aggregate"
    },
    {
      icon: "cogs",
      text: "Settings",
      href: "/settings"
    },
];

const UnifiedMenuAndFilters = () => {
    const [isMinimised, setIsMinimised] = useState(false);

    const wrapWithLink = (item: MenuItem, node: ReactNode) => {
        return (
            <Link to={item.href!} className="dropdown-link">
            {node}
            </Link>
        );
    };

    const Item = (props: { item: MenuItem }) => {
        const node = (
            <div className="layout-desktop-unified-menu-item">
                <Icon name={props.item.icon} />
                <div className="layout-desktop-unified-menu-item-text">{props.item.text}</div>
            </div>
        );
    
        return props.item.href ? wrapWithLink(props.item, node) : node;
    }
    return (
        <Translation>
          {(t) => (
            <div 
                className={`layout-desktop-unified-menu`}
                onPointerEnter={() => setIsMinimised(false)}
                onPointerLeave={() => setIsMinimised(true)}
            >
                <div style={{padding: '10px'}}>
                    {menuItems.map((item) => (
                        <Item item={item} />
                    ))}
                </div>
                {!isMinimised && (<Divider />)}
                {!isMinimised && (<div>
                    <ListOfPresetFilters />
                </div>)}
            </div>
          )}
        </Translation>
    )
}

export default UnifiedMenuAndFilters
