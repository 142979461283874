import i18n from 'i18next';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import 'moment/dist/locale/fi';
import 'moment/dist/locale/sv';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/nl-be';

import * as React from 'react';
import { DateRangePicker } from 'react-dates';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { Button, Dropdown, Icon } from 'semantic-ui-react';

import type { TFunction } from 'i18next';
import type { DayOfWeekShape } from 'react-dates';
import type { DropdownItemProps } from 'semantic-ui-react';

import { DATE_FORMAT, getPrettyDate, parseStringDateRange } from 'src/Utilities/dates';

interface DateRangePickerFieldProps {
  onChange: (value: string | false) => void;
  startDate?: moment.Moment | null;
  endDate?: moment.Moment | null;
  value: number | string | null;
  disabled: boolean;
  startDatePlaceholderText?: string;
  endDatePlaceholderText?: string;
  showDefaultInputIcon?: boolean;
  namespace?: string;
  t: TFunction;
}

interface DateRangePickerFieldState {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  value: number | string | null;
  focusedInput: 'startDate' | 'endDate' | null;
}

class DateRangePickerField extends React.PureComponent<DateRangePickerFieldProps, DateRangePickerFieldState> {
  monthsMap: DropdownItemProps[];
  yearsMap: DropdownItemProps[];

  constructor(props: DateRangePickerFieldProps) {
    super(props);

    const dates = this.getDerivedStatesFromValue(props);

    this.state = {
      startDate: dates.startDate || null,
      endDate: dates.endDate || null,
      value: dates.value,
      focusedInput: null
    };

    this.yearsMap = [];

    let momentLocale = 'en-gb';
    switch (i18n.language) {
      case 'be':
        momentLocale = 'nl-be';
        break;
      case 'se':
        momentLocale = 'sv';
        break;
      default:
        momentLocale = i18n.language || momentLocale;
    }

    moment.locale(momentLocale);
    this.monthsMap = moment.months().map((label, value) => ({
      text: label,
      value
    }));

    for (let i = -5; i <= 5; i++) {
      const year: number = moment().year() - i;
      this.yearsMap.push({
        text: year,
        value: year
      });
    }
  }

  getDerivedValueFromState = () => {
    const { startDate, endDate } = this.state;

    if (startDate !== null && endDate !== null) {
      // daterange is now between
      // bw:1-1
      return `bw:${startDate.startOf('day').unix()}-${endDate.endOf('day').unix()}`;
    } else if (startDate !== null) {
      // we only have set startDate
      // gt:1
      return `gt:${startDate.startOf('day').unix()}`;
    } else if (endDate !== null) {
      // we only have set endDate
      // lt:1
      return `lt:${endDate.endOf('day').unix()}`;
    } else {
      // no startDate and no endDate selected
      // false
      return false;
    }
  };

  getDerivedStatesFromValue = (props: DateRangePickerFieldProps): DateRangePickerFieldProps => {
    const { value, startDate, endDate } = props;
    if (typeof value === 'string') {
      // value is a string (eg. 123:456) = modified daterange
      const { startDate: startDateNumber, endDate: endDateNumber } = parseStringDateRange(value);

      return {
        startDate: startDateNumber !== null ? moment.unix(startDateNumber) : null,
        endDate: endDateNumber !== null ? moment.unix(endDateNumber) : null,
        ...props,
        value
      };
    }

    return {
      startDate,
      endDate,
      ...props,
      value: ['number', 'string'].includes(typeof value) ? value : null
    };
  };

  datesChange = (dates: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
    const { startDate, endDate } = dates;
    this.setState(
      () => {
        return {
          startDate,
          endDate
        };
      },
      () => {
        if (startDate && endDate) {
          this.setState({ focusedInput: null });
        }
        if (this.props.onChange) {
          const value = this.getDerivedValueFromState();
          this.props.onChange(value);
        }
      }
    );
  };

  renderInformation = () => {
    return (
      <div style={{ padding: '10px' }}>
        {this.state.startDate && (
          <Button
            secondary={true}
            icon={true}
            basic={true}
            labelPosition="left"
            onClick={() => this.setState({ startDate: null })}
          >
            <Icon name="delete" />
            {getPrettyDate(this.state.startDate.unix(), { format: DATE_FORMAT })}
          </Button>
        )}
        {this.state.endDate && (
          <Button
            secondary={true}
            icon={true}
            basic={true}
            labelPosition="left"
            onClick={() => this.setState({ endDate: null })}
          >
            <Icon name="delete" />
            {getPrettyDate(this.state.endDate.unix(), { format: DATE_FORMAT })}
          </Button>
        )}
      </div>
    );
  };

  renderMonths = (renderArguments: {
    month: moment.Moment;
    onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
    onYearSelect: (currentMonth: moment.Moment, newYearVal: string) => void;
  }) => {
    const { month, onMonthSelect, onYearSelect } = renderArguments;
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <Dropdown
            value={month.month()}
            scrolling={true}
            onChange={(e: any, d: any) => {
              onMonthSelect(month, d.value);
            }}
            options={this.monthsMap}
          />
        </div>
        <div style={{ width: '10px' }} />
        <div>
          <Dropdown
            value={month.year()}
            scrolling={true}
            onChange={(e: any, d: any) => {
              onYearSelect(month, d.value);
            }}
            options={this.yearsMap}
          />
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <DateRangePicker
        disabled={this.props.disabled}
        startDate={this.state.startDate}
        startDateId={'dateRangePickerStart_' + this.props.namespace}
        transitionDuration={100}
        endDate={this.state.endDate}
        endDateId={'dateRangePickerEnd_' + this.props.namespace}
        onDatesChange={this.datesChange}
        focusedInput={this.state.focusedInput}
        onFocusChange={(focusedInput) => this.setState({ focusedInput })}
        numberOfMonths={3}
        showClearDates={true}
        startDatePlaceholderText={this.props.startDatePlaceholderText || t('DATEPICKER_START_DATE')}
        endDatePlaceholderText={this.props.endDatePlaceholderText || t('DATEPICKER_END_DATE')}
        renderCalendarInfo={this.renderInformation}
        displayFormat={t('DATEFORMAT_FULL')}
        firstDayOfWeek={Number(t('FIRST_DAY_OF_THE_WEEK')) as DayOfWeekShape}
        customArrowIcon={<Icon name="arrow right" />}
        renderMonthElement={this.renderMonths}
        small={true}
        withPortal={true}
        block={true}
        isOutsideRange={() => false}
        minimumNights={0}
        showDefaultInputIcon={this.props.showDefaultInputIcon || false}
        monthFormat="YYYY MMMM"
        renderDayContents={(day) => getPrettyDate(day.toString(), { format: 'D' })}
      />
    );
  }
}

export default withTranslation('translations')(DateRangePickerField);
