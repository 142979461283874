import type { Priority } from 'src/types/Priority';
import { FETCH_PRIORITIES_SUCCESS } from '../actions';

const initialState: Priority[] = [];

const prioritiesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_PRIORITIES_SUCCESS: {
      return action.payload.priorities;
    }
    default:
      return state;
  }
};

export default prioritiesReducer;
