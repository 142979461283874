import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Accordion, AccordionTitle, Button, ButtonGroup, Dropdown } from 'semantic-ui-react';

import type { FC } from 'react';
import type { ConnectedProps } from 'react-redux';

import SingleLineAccordionTab from './SingleLineAccordionTab';
import { StaticTabs } from 'src/types/TicketList';
import { doneTicketsOrdering, filterTicketList, ticketOrdering } from 'src/Utilities/ticketList';

import type { TabFilter } from 'src/types/Filter';
import type { State } from 'src/types/initialState';
import type { TicketListTicket } from 'src/types/Ticket';

import './SingleLineTicketView.css';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SingleLineTicketViewProps extends ConnectedProps<typeof connector> {}

const SingleLineTicketView: FC<SingleLineTicketViewProps> = ({
  ticketListTab,
  categories,
  personalData,
  usersList
}) => {
  const filters = ticketListTab.filters ?? ({} as TabFilter);
  const { tickets: contents, sorting, direction } = ticketListTab;
  const orderedTickets = ticketOrdering(contents, sorting, direction, personalData.userCustomTicketOrdering);
  const filterArgs = {
    categories,
    personalData,
    filters,
    usersList
  };

  const todoTickets = useMemo(
    () =>
      filterTicketList({
        ...filterArgs,
        status: 'todo',
        tickets: orderedTickets.filter((ticket) => ticket.status === 'todo')
      }),
    [categories, personalData, filters, orderedTickets, usersList]
  );
  const doingTickets = useMemo(
    () =>
      filterTicketList({
        ...filterArgs,
        status: 'doing',
        tickets: orderedTickets.filter((ticket) => ticket.status === 'doing')
      }),
    [categories, personalData, filters, orderedTickets, usersList]
  );
  const doneTickets = useMemo(
    () =>
      filterTicketList({
        ...filterArgs,
        tickets: doneTicketsOrdering(ticketListTab.tickets.filter((ticket) => ticket.status === 'done')),
        status: 'done'
      }) as TicketListTicket[],
    [categories, personalData, filters, contents, usersList]
  );

  const accordionTabs: { index: number; status: 'todo' | 'doing' | 'done'; tickets: TicketListTicket[] }[] = useMemo(
    () => [
      { index: 0, status: 'todo', tickets: todoTickets },
      { index: 1, status: 'doing', tickets: doingTickets },
      { index: 2, status: 'done', tickets: doneTickets }
    ],
    [todoTickets, doingTickets, doneTickets]
  );

  return (
    <div className="toastSearchTarget">
      <Accordion fluid styled style={{ borderRadius: 0, flex: 1, height: '100%' }}>
        {accordionTabs.map((tab) => (
          <SingleLineAccordionTab index={tab.index} status={tab.status} tickets={tab.tickets} />
        ))}

        <AccordionTitle>
          <ButtonGroup color="blue">
            <Button basic color="blue">
              Perform on selected
            </Button>
            <Dropdown
              className="button icon"
              floating
              options={[
                { text: 'Deprecate', value: 'deprecate' },
                { text: 'Close as done', value: 'closeAsDone' }
              ]}
              trigger={<></>}
            />
          </ButtonGroup>
        </AccordionTitle>
      </Accordion>
    </div>
  );
};

const connector = connect((state: State) => {
  const activeTab = Object.values(state.ticketListTabs).find((tab) => tab.activeTab);

  return {
    ticketListTab: state.ticketListTabs[activeTab?.id ?? StaticTabs.MAIN_VIEW],
    usersList: state.usersList.usersList,
    categories: state.categories,
    personalData: state.userData,
    ticketTypes: state.ticketTypes,
    channels: state.channels,
    priorities: state.ticketPriorities
  };
});

export default withRouter(connector(SingleLineTicketView));
